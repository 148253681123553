import { API, graphqlOperation } from "aws-amplify";
import * as customQueries from '../../graphql/custom-queries';
import { updateWellFloodEvent } from "../../graphql/mutations";

export async function getFloodEvents(sensorID, startDate, endDate) {
  let nextToken = null;
  let queryItems = [];

  let queryVariables = {
    sensorID: sensorID,
    startTimestamp: {
      between: [startDate, endDate]
    },
    sortDirection: 'DESC',
  };

  do {
    queryVariables.nextToken = nextToken;

    const queryResult = await API.graphql({
      query: customQueries.wellFloodEventBySensor,
      variables: queryVariables
    });

    queryItems = queryItems.concat(queryResult.data['wellFloodEventBySensor'].items);
    nextToken = queryResult.data['wellFloodEventBySensor'].nextToken;
  } while (nextToken != null);

  return queryItems;
}

export async function updateFloodEvent(floodEventInput) {
  const mutationResult = await API.graphql(graphqlOperation(updateWellFloodEvent, {input: floodEventInput}));

  return mutationResult;
}
