export const accessRecordUserByUser = /* GraphQL */ `
  query AccessRecordUserByUser(
    $userID: ID
  ) {
    accessRecordUserByUser(
      userID: $userID
    ) {
      items {
        accessRecord {
          accessRecordLoggers {
            items {
              logger {
                id
                siteName
                createdAt
                serialNumber
                siteLatitudeDegrees
                siteLongitudeDegrees
                monitored
                updatedAt
                events(limit: 1, sortDirection: DESC) {
                  items {
                    batteryLevelPercent
                    batteryVoltageV
                    createdAt
                    eventName
                    eventTimeStamp
                    id
                    latitudeDegrees
                    loggerID
                    longitudeDegrees
                    updatedAt
                  }
                }
                sensors {
                  items {
                    id
                    loggerID
                    name
                    serialNumber
                    type
                    updatedAt
                    events(limit: 288, sortDirection: DESC) {
                      items {
                        createdAt
                        eventName
                        eventTimeStamp
                        id
                        reading
                        sensorID
                        supplyVoltage
                        temperatureC
                        updatedAt
                      }
                    }
                  }
                }
                notes(limit: 100, sortDirection: DESC) {
                  items {
                    id
                    loggerID
                    userID
                    title
                    body
                    archive
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const sensorEventBySensor = /* GraphQL */ `
  query SensorEventBySensor(
    $sensorID: ID
    $eventTimeStamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSensorEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sensorEventBySensor(
      sensorID: $sensorID
      eventTimeStamp: $eventTimeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sensorID
        eventName
        eventTimeStamp
        reading
        temperatureC
        supplyVoltage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCloudAlertRules = /* GraphQL */ `
  query ListCloudAlertRules(
    $filter: ModelCloudAlertRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCloudAlertRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        loggerID
        sensorID
        subSensorID
        name
        siteName
        parameter
        category
        condition
        threshold
        durationSeconds
        emailList
        smsList
        enabled
        createdAt
        updatedAt
        stateEvents {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const cloudAlertStateEventByCloudAlertRule = /* GraphQL */ `
  query CloudAlertStateEventByCloudAlertRule(
    $cloudAlertRuleID: ID
    $eventTimeStamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCloudAlertStateEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cloudAlertStateEventByCloudAlertRule(
      cloudAlertRuleID: $cloudAlertRuleID
      eventTimeStamp: $eventTimeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cloudAlertRuleID
        loggerID
        sensorID
        subSensorID
        state
        eventTimeStamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const wellFloodEventBySensor = /* GraphQL */ `
  query WellFloodEventBySensor(
    $sensorID: ID
    $startTimestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWellFloodEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wellFloodEventBySensor(
      sensorID: $sensorID
      startTimestamp: $startTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sensorID
        startTimestamp
        endTimestamp
        drawdownRateMmPerHour
        drawdownTimeSeconds
        peakWaterLevelM
        excludeFromSensorReport
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
