export async function asyncForEach(array, callback) {
  let promises = [];

  for (let index = 0; index < array.length; index++) {
    promises.push(callback(array[index], index, array));
  }

  await Promise.all(promises);
}

export function getArrayMinValue(array) {
  let arrayMinValue = null;

  arrayMinValue = Math.min(...array);

  return arrayMinValue;
}

export function getArrayAvgValue(array) {
  let arrayAvgValue = null;

  let sum = 0;
  let counter = 0;
  for ( let i = 0; i < array.length; i++ ) {
    if (array[i] != null) {
      sum += Number(array[i]);
      counter += 1;
    }
  }

  arrayAvgValue = sum / counter;

  return arrayAvgValue;
}

export function getArrayMaxValue(array) {
  let arrayMaxValue = null;

  arrayMaxValue = Math.max(...array);

  return arrayMaxValue;
}
