/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "ca-central-1",
  "aws_cognito_identity_pool_id": "ca-central-1:d8bfeba4-77f1-4c06-b654-833398905262",
  "aws_cognito_region": "ca-central-1",
  "aws_user_pools_id": "ca-central-1_IPKt5Pqp5",
  "aws_user_pools_web_client_id": "7maqt3k5narib21s6nec0dbl9u",
  "oauth": {},
  "aws_cognito_username_attributes": [
      "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ],
  "aws_appsync_graphqlEndpoint": "https://juurctepkzfrhhc5cjg5y2dkqm.appsync-api.ca-central-1.amazonaws.com/graphql",
  "aws_appsync_region": "ca-central-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
