import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './screens/App.js';
import reportWebVitals from './reportWebVitals';
import Amplify from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import theme from './assets/styles/theme';

import config from './aws-exports';

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
});
config.graphql_headers = getIdToken;
Amplify.configure(config);

ReactDOM.render(
  <ThemeProvider theme={theme}>
      <CssBaseline/>
      <App/>
    </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
