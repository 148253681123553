import React, { useState, useContext } from 'react';
import { Typography, Container, TextField, Grid, Box, Button, Snackbar, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { navigate } from '@reach/router';
import { Auth } from "aws-amplify";

import AuthenticationContext from "../../shared/contexts/authenticationContext";
import { globalDataStore } from '../../shared/contexts/globalDataContext';

import novionLogoImage from '../../assets/images/novion-logo-white-wordmark.svg';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SignIn(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [createNewPassword, setCreateNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const authContext = useContext(AuthenticationContext);
  const updateCurrentUser = authContext.updateCurrentUser;

  const { state, dispatch } = useContext(globalDataStore);

  function handleResetPassword() {
    navigate("/reset-password");
  }

  const onChange = (key, value) => {
    setErrorMessage(null);
    if (key === "email") {
      setEmail(value);
    } else if (key === "password") {
      setPassword(value);
    }
    else if (key === "new_password") {
      setNewPassword(value);
    }
  }

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      signIn();
    }
  };

  function handleNewPassword() {
    Auth.completeNewPassword (
      authContext.user,
      newPassword
    )
    .then(user => {
      navigate("/");
    })
    .catch(err => {
      console.error("error confirming new password: ", err);
      setErrorMessage(err.message);
      setIsLoading(false);
    });
  }

  function signIn() {
    setIsLoading(true);
    dispatch({ type: "cleanup", payload: null });

    Auth.signIn(email.toLowerCase(), password)
    .then(user => {
      console.log(user);
      updateCurrentUser(user);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setCreateNewPassword(true);
        setIsLoading(false);
      } 
      else if (user.challengeName == null){ 
        updateCurrentUser(user);
        setIsLoading(false);
        navigate("/");
      }
    })
    .catch(err => {
      setIsLoading(false);
      setErrorMessage(err.message);
    });
  }
  
  return(
    <Grid container direction='row' style={{maxWidth: '98%'}}>
      <Grid item style={{width: '50%'}}>
        <Box height="100vh" display="flex" justifyContent='center' alignItems='center' style={{background: 'linear-gradient(180deg, #4350AF 22.66%, #4584D8 100%)'}}>
          <img className="logo" src={novionLogoImage} alt="Resource Managent Platform by Novion" height='70%'/>
        </Box>
      </Grid>
      <Grid item style={{width: '50%', marginTop: 'auto', marginBottom: 'auto'}}>
        {!createNewPassword && (
          <React.Fragment>
            <Typography style={{marginLeft: '25%', /* marginRight: 50 */}} variant="h5">Sign in</Typography>
            <form noValidate autoComplete="off">
              <Box style={{marginLeft: '25%', marginRight: '25%'}} my={4}>
                <TextField label="Email " required type="email" fullWidth onChange={event => onChange('email', event.target.value)} margin="normal" size="medium"/>
              </Box>
              <Box style={{marginLeft: '25%', marginRight: '25%'}} my={4}>
                <TextField label="Password " required type="password" fullWidth onChange={event => onChange('password', event.target.value)} onKeyDown={event => handleEnterKey(event)} margin="normal" size="medium"/>
              </Box>
              <Grid container>
                <Grid item style={{marginLeft: '25%', width: '100%', marginRight: '25%'}}>
                  <Button color='primary' variant='contained' onClick={signIn} fullWidth>
                    {!isLoading && 'Sign In'}
                    {isLoading && <CircularProgress color='info' size={24} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Button
              style={{ marginLeft: '25%', marginTop: 12, fontSize: 12, textTransform: 'none' }}
              onClick={handleResetPassword}
            >
              Reset Password
            </Button>
          </React.Fragment>
        )}
        {createNewPassword && (
          <React.Fragment>
            <Typography style={{marginLeft: '25%', /* marginRight: 50 */}} variant="h5">Create new password</Typography>
            <form noValidate autoComplete="off">
              <Box style={{marginLeft: '25%', marginRight: '25%'}} my={4}>
                <TextField label="New Password " required type="password" fullWidth onChange={event => onChange('new_password', event.target.value)} onKeyDown={event => handleEnterKey(event)} margin="normal" size="medium"/>
              </Box>
              <Grid container>
                <Grid item style={{marginLeft: '25%', width: '100%', marginRight: '25%'}}>
                  <Button color='primary' variant='contained' onClick={handleNewPassword} fullWidth>
                    {!isLoading && 'Submit'}
                    {isLoading && <CircularProgress color='info' size={24} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </React.Fragment>
        )}
        <Snackbar open={errorMessage ? true : false} autoHideDuration={2500}>
          <Alert severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}