/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLogger = /* GraphQL */ `
  mutation CreateLogger(
    $input: CreateLoggerInput!
    $condition: ModelLoggerConditionInput
  ) {
    createLogger(input: $input, condition: $condition) {
      id
      serialNumber
      siteName
      createdAt
      updatedAt
      events {
        items {
          id
          loggerID
          eventName
          eventTimeStamp
          batteryLevelPercent
          latitudeDegrees
          longitudeDegrees
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          loggerID
          serialNumber
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateLogger = /* GraphQL */ `
  mutation UpdateLogger(
    $input: UpdateLoggerInput!
    $condition: ModelLoggerConditionInput
  ) {
    updateLogger(input: $input, condition: $condition) {
      id
      serialNumber
      siteName
      createdAt
      updatedAt
      events {
        items {
          id
          loggerID
          eventName
          eventTimeStamp
          batteryLevelPercent
          latitudeDegrees
          longitudeDegrees
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          loggerID
          serialNumber
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteLogger = /* GraphQL */ `
  mutation DeleteLogger(
    $input: DeleteLoggerInput!
    $condition: ModelLoggerConditionInput
  ) {
    deleteLogger(input: $input, condition: $condition) {
      id
      serialNumber
      siteName
      createdAt
      updatedAt
      events {
        items {
          id
          loggerID
          eventName
          eventTimeStamp
          batteryLevelPercent
          latitudeDegrees
          longitudeDegrees
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          loggerID
          serialNumber
          name
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createLoggerEvent = /* GraphQL */ `
  mutation CreateLoggerEvent(
    $input: CreateLoggerEventInput!
    $condition: ModelLoggerEventConditionInput
  ) {
    createLoggerEvent(input: $input, condition: $condition) {
      id
      loggerID
      eventName
      eventTimeStamp
      batteryLevelPercent
      latitudeDegrees
      longitudeDegrees
      createdAt
      updatedAt
    }
  }
`;
export const updateLoggerEvent = /* GraphQL */ `
  mutation UpdateLoggerEvent(
    $input: UpdateLoggerEventInput!
    $condition: ModelLoggerEventConditionInput
  ) {
    updateLoggerEvent(input: $input, condition: $condition) {
      id
      loggerID
      eventName
      eventTimeStamp
      batteryLevelPercent
      latitudeDegrees
      longitudeDegrees
      createdAt
      updatedAt
    }
  }
`;
export const deleteLoggerEvent = /* GraphQL */ `
  mutation DeleteLoggerEvent(
    $input: DeleteLoggerEventInput!
    $condition: ModelLoggerEventConditionInput
  ) {
    deleteLoggerEvent(input: $input, condition: $condition) {
      id
      loggerID
      eventName
      eventTimeStamp
      batteryLevelPercent
      latitudeDegrees
      longitudeDegrees
      createdAt
      updatedAt
    }
  }
`;
export const createSensor = /* GraphQL */ `
  mutation CreateSensor(
    $input: CreateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    createSensor(input: $input, condition: $condition) {
      id
      loggerID
      serialNumber
      name
      type
      createdAt
      updatedAt
      events {
        items {
          id
          sensorID
          eventName
          eventTimeStamp
          reading
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSensor = /* GraphQL */ `
  mutation UpdateSensor(
    $input: UpdateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    updateSensor(input: $input, condition: $condition) {
      id
      loggerID
      serialNumber
      name
      type
      createdAt
      updatedAt
      events {
        items {
          id
          sensorID
          eventName
          eventTimeStamp
          reading
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSensor = /* GraphQL */ `
  mutation DeleteSensor(
    $input: DeleteSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    deleteSensor(input: $input, condition: $condition) {
      id
      loggerID
      serialNumber
      name
      type
      createdAt
      updatedAt
      events {
        items {
          id
          sensorID
          eventName
          eventTimeStamp
          reading
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSensorEvent = /* GraphQL */ `
  mutation CreateSensorEvent(
    $input: CreateSensorEventInput!
    $condition: ModelSensorEventConditionInput
  ) {
    createSensorEvent(input: $input, condition: $condition) {
      id
      sensorID
      eventName
      eventTimeStamp
      reading
      createdAt
      updatedAt
    }
  }
`;
export const updateSensorEvent = /* GraphQL */ `
  mutation UpdateSensorEvent(
    $input: UpdateSensorEventInput!
    $condition: ModelSensorEventConditionInput
  ) {
    updateSensorEvent(input: $input, condition: $condition) {
      id
      sensorID
      eventName
      eventTimeStamp
      reading
      createdAt
      updatedAt
    }
  }
`;
export const deleteSensorEvent = /* GraphQL */ `
  mutation DeleteSensorEvent(
    $input: DeleteSensorEventInput!
    $condition: ModelSensorEventConditionInput
  ) {
    deleteSensorEvent(input: $input, condition: $condition) {
      id
      sensorID
      eventName
      eventTimeStamp
      reading
      createdAt
      updatedAt
    }
  }
`;

export const createSensorEventDownloadRequest = /* GraphQL */ `
  mutation CreateSensorEventDownloadRequest(
    $input: CreateSensorEventDownloadRequestInput!
    $condition: ModelSensorEventDownloadRequestConditionInput
  ) {
    createSensorEventDownloadRequest(input: $input, condition: $condition) {
      id
      sensorID
      email
      startTime
      endTime
      timeZone
      siteName
      createdAt
      updatedAt
    }
  }
`;

export const createCloudAlertRule = /* GraphQL */ `
mutation CreateCloudAlertRule(
  $input: CreateCloudAlertRuleInput!
  $condition: ModelCloudAlertRuleConditionInput
) {
  createCloudAlertRule(input: $input, condition: $condition) {
    id
    userID
    loggerID
    sensorID
    subSensorID
    name
    siteName
    category
    parameter
    condition
    threshold
    durationSeconds
    emailList
    smsList
    enabled
    createdAt
    updatedAt
    stateEvents {
      items {
        id
        cloudAlertRuleID
        loggerID
        sensorID
        subSensorID
        state
        eventTimeStamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
}
`;

export const deleteCloudAlertRule = /* GraphQL */ `
  mutation DeleteCloudAlertRule(
    $input: DeleteCloudAlertRuleInput!
    $condition: ModelCloudAlertRuleConditionInput
  ) {
    deleteCloudAlertRule(input: $input, condition: $condition) {
      id
      userID
      loggerID
      sensorID
      subSensorID
      name
      siteName
      category
      parameter
      condition
      threshold
      durationSeconds
      emailList
      smsList
      enabled
      createdAt
      updatedAt
      stateEvents {
        items {
          id
          cloudAlertRuleID
          loggerID
          sensorID
          subSensorID
          state
          eventTimeStamp
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      loggerID
      userID
      title
      body
      archive
      createdAt
      updatedAt
    }
  }
`;

export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      loggerID
      userID
      title
      body
      archive
      createdAt
      updatedAt
    }
  }
`;

export const updateWellFloodEvent = /* GraphQL */ `
  mutation UpdateWellFloodEvent(
    $input: UpdateWellFloodEventInput!
    $condition: ModelWellFloodEventConditionInput
  ) {
    updateWellFloodEvent(input: $input, condition: $condition) {
      id
      sensorID
      startTimestamp
      endTimestamp
      drawdownRateMmPerHour
      drawdownTimeSeconds
      peakWaterLevelM
      excludeFromSensorReport
      createdAt
      updatedAt
    }
  }
`;
