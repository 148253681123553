import React, { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Typography, Button, Paper, InputLabel, Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useScrollTrigger, List, ListItem, ListItemText, Divider, Dialog, DialogContent, TextField, CircularProgress, Snackbar, IconButton } from '@material-ui/core';
import { navigate, useLocation } from "@reach/router";
import { Web, FiberManualRecord, AssessmentRounded, ArrowBackIos, Create, Label, Subject, Cancel, Save, Delete } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';

import Navigator from '../../../shared/components/navigator.js';
import { globalDataStore } from '../../../shared/contexts/globalDataContext.js';
import { asyncForEach } from '../../../shared/utils/arrays.js';
import { apiCallArchiveNote, apiCallCreateNote, getLatestCloudAlertStateEvent, getLatestNotificationStateEvent } from '../../../shared/api-calls/notification-api-calls.js';
import { findResource, findResourcePropertyValue, getAlertCategory } from '../../../shared/utils/alert-utils.js';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SettingsScreen(props) {
  const [ resource, setResource ] = useState({notes: []});
  const [ cloudAlertRulesForLogger, setCloudAlertRulesForLogger ] = useState([]);
  const [ recentAlertHistory, setRecentAlertHistory ] = useState([]);

  const [ selectedNoteIndex, setSelectedNoteIndex ] = useState(0);
  const [ newNoteModalOpen, setNewNoteModalOpen ] = useState(false);
  const [ newNoteTitle, setNewNoteTitle ] = useState(null);
  const [ newNoteBody, setNewNoteBody ] = useState(null);
  const [ saveNewNoteLoading, setSaveNewNoteLoading ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ refreshArchivedNote, setRefreshArchivedNote ] = useState(null);
  const [ refreshNewNote, setRefreshNewNote ] = useState(null);

  const { state, dispatch } = useContext(globalDataStore);

  const handleListItemClick = (event, index) => {
    setSelectedNoteIndex(index);
  };

  const handleNewNoteTitleChange = (event) => {
    setNewNoteTitle(event.target.value);
  };

  const handleNewNoteBodyChange = (event) => {
    setNewNoteBody(event.target.value);
  };

  const handleArchiveNote = () => {
    let noteToArchive = resource.notes[selectedNoteIndex];
    noteToArchive.archive = 'true';

    apiCallArchiveNote(noteToArchive)
    .catch(e => {
      console.log("error in apiCallArchiveNote", e);
    })
    .then((apiCallArchiveNoteResult) => {
      setRefreshArchivedNote(apiCallArchiveNoteResult.data.updateNote);
    });
  };

  const handleSaveNewNote = () => {
    setErrorMessage(null);
    setSaveNewNoteLoading(true);
    
    Auth.currentAuthenticatedUser()
    .then(user => {
      let newNote = {
        loggerID: props.loggerID,
        userID: user.attributes.email,
        title: newNoteTitle,
        body: newNoteBody,
      };
        
      apiCallCreateNote(newNote)
      .catch(e => {
        setSaveNewNoteLoading(false);
        console.log("error in apiCallCreateNote", e);
        setErrorMessage('Error while attempting to create Note!');
        setNewNoteModalOpen(false);
      })
      .then((apiCallCreateNoteResult) => {
        setSaveNewNoteLoading(false);
        setNewNoteModalOpen(false);
        setNewNoteTitle(null);
        setNewNoteBody(null);
        setRefreshNewNote(apiCallCreateNoteResult.data.createNote);
      });
    });
  };

  useEffect(() => {
    if (refreshArchivedNote != null) {
      if (selectedNoteIndex > 0) {
        setSelectedNoteIndex(selectedNoteIndex - 1);
      }

      let updatedResource = resource;
      updatedResource.notes = updatedResource.notes.filter(note => note.archive !== 'true');
      setResource(updatedResource);

      setRefreshArchivedNote(null);
    }
  }, [ refreshArchivedNote ]);

  useEffect(() => {
    if (refreshNewNote != null) {
      let updatedResource = resource;
      updatedResource.notes.push(refreshNewNote);
      setResource(updatedResource);

      setRefreshNewNote(null);
    }
  }, [ refreshNewNote ]);
  
  useEffect(() => {
    if (state.resourceDetails != null) {
      let updatedResource = findResource(props.loggerID, state.resourceDetails, props.location.state.name);
      updatedResource.notes = updatedResource.notes.filter(note => note.archive !== 'true');

      setResource(updatedResource);
    }

    if (state.alertRules != null) {
      const alertRulesForLogger = state.alertRules.filter(alertRule => alertRule.loggerID === props.loggerID);
      setCloudAlertRulesForLogger(alertRulesForLogger);

      console.log('alertRulesForLogger: ', alertRulesForLogger);
      let cloudAlertStateEventsForLogger = [];
      asyncForEach(alertRulesForLogger, async alertRuleForLogger => {
        const latestCloudAlertStateEvent = await getLatestCloudAlertStateEvent(alertRuleForLogger.id)
        .catch(e => {
          console.log("error in getLatestCloudAlertStateEvent", e);
        });

        cloudAlertStateEventsForLogger.push(latestCloudAlertStateEvent);
      })
      .then(() => {
        setRecentAlertHistory(cloudAlertStateEventsForLogger);
      });
    }
  }, []);

  return (
    <div style={{flexGrow: 1, marginLeft: 240, backgroundColor: '#F7F7F7'}}>
      <Navigator activeDrawerItem='Sites' />
      <Grid style={{margin: 24, width: '95%'}}>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50}} alignItems='center'>
          <Grid item style={{marginLeft: 10, marginRight: 10}}>
            <Web fontSize='large' color='primary' />
          </Grid>
          <Grid item >
            <Typography variant='body1' style={{fontWeight: 'bold'}}>Site Details</Typography>
          </Grid>
        </Grid>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50, marginTop: 14}} alignItems='center'>
          <Grid style={{marginLeft: 10}} container item justifyContent='space-between' alignItems='center'>
            <Button aria-controls='back' color='primary' variant='outlined' onClick={() => navigate('/sites')} style={{ borderRadius: 4, width: 150,/* height: '60%' *//* , marginRight: '2%' */ }}>
              <ArrowBackIos style={{ marginRight: '10%' }} />
              <Typography>Site List</Typography>
            </Button>
            <Button aria-controls='analyze' color='primary' variant='contained' onClick={() => navigate(`/resource/${props.loggerID}/analysis`, { state: { name: resource.name } })} style={{ borderRadius: 4, height: '60%', marginRight: '2%' }}>
              <AssessmentRounded style={{ marginRight: '10%' }} />
              <Typography style={{ color: 'white' }}>Analyze</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container style={{marginTop: 24}} >
          <Grid item xs={4}>
            <Paper style={{backgroundColor: 'white', height: 96, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
              <Typography align='center' variant='body2' gutterBottom>Site Name</Typography>
              <Typography align='center' variant='h5'>{resource.name}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper style={{backgroundColor: 'white', height: 96, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
              <Typography align='center' variant='body2' gutterBottom>Sensor Type</Typography>
              <Typography align='center' variant='h5'>{resource.sensor}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper style={{backgroundColor: 'white', height: 96, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
              <Typography align='center' variant='body2' gutterBottom>Site Type</Typography>
              <Typography align='center' variant='h5'>{resource.siteType}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper style={{backgroundColor: 'white', height: 96, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
              <Typography align='center' variant='body2' gutterBottom>Last Online Date</Typography>
              <Typography align='center' variant='h5'>{new Date(resource.timestamp).toLocaleDateString()}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={1}>
            <Paper style={{backgroundColor: 'white', height: 96, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
              <Typography align='center' variant='body2' gutterBottom>Online</Typography>
              <Typography align='center' variant='h5'>{<FiberManualRecord fontSize='small' style={{color: resource.online ? '#59AF43' : '#434343'}}/>}</Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid container style={{marginTop: 24}} >
          <Grid item xs={4}>
            <Paper style={{backgroundColor: 'white', height: 96, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
              <Typography align='center' variant='body2' gutterBottom>Logger Serial Number</Typography>
              <Typography align='center' variant='h5'>{resource.loggerSerialNumber}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper style={{backgroundColor: 'white', height: 96, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
              <Typography align='center' variant='body2' gutterBottom>Sensor Model</Typography>
              <Typography align='center' variant='h5'>{resource.sensorModel}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Paper style={{backgroundColor: 'white', height: 96, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
              <Typography align='center' variant='body2' gutterBottom>Last Reading</Typography>
              <Typography align='center' variant='h5'>{`${resource.latestValue} ${resource.readingUnitSuffix}`}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={1}>
            <Paper style={{backgroundColor: 'white', height: 96, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
              <Typography align='center' variant='body2' gutterBottom>Battery Level</Typography>
              <Typography align='center' variant='h5'>{resource.batteryLevel}</Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid container style={{marginTop: 24}} >
          <Grid item xs={6}>
            <Paper style={{backgroundColor: 'white', height: 50, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column', borderRadius: 0}}>
              <Typography variant='body2' style={{marginLeft: 10, fontWeight: 'bold'}}>Recent Alert History</Typography>
            </Paper>
            <Grid style={{marginRight: 12}}>
              <TableContainer component={Paper} style={{borderRadius: 0}}>
                <Table size='small' stickyHeader aria-label='Recent Alert History Table'>
                  <TableHead>
                    <TableRow selected>
                      <TableCell>Site</TableCell>
                      <TableCell align='left'>Alert Category</TableCell>
                      <TableCell align='left'>State</TableCell>
                      <TableCell align='left'>Date</TableCell>
                      <TableCell align='left'>Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {recentAlertHistory.map((recentAlertHistoryObject) => (
                    <TableRow key={recentAlertHistoryObject.id}>
                      <TableCell component='th' scope='row'>
                        {(cloudAlertRulesForLogger.find(cloudAlertRule => cloudAlertRule.id === recentAlertHistoryObject.cloudAlertRuleID)).siteName}
                      </TableCell>
                      <TableCell align='left'>{(cloudAlertRulesForLogger.find(cloudAlertRule => cloudAlertRule.id === recentAlertHistoryObject.cloudAlertRuleID)).category}</TableCell>
                      <TableCell align='left'>{recentAlertHistoryObject.state === 'true' ? 'Active' : 'Inactive'}</TableCell>
                      <TableCell align='left'>{new Date(recentAlertHistoryObject.eventTimeStamp).toLocaleDateString()}</TableCell>
                      <TableCell align='left'>{new Date(recentAlertHistoryObject.eventTimeStamp).toLocaleTimeString()}</TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Paper style={{backgroundColor: 'white', height: 50, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column', borderRadius: 0}}>
              <Typography variant='body2' style={{marginLeft: 10, fontWeight: 'bold'}}>Site Notes</Typography>
            </Paper>
            <Grid container>
              <Grid item xs={4}>
                <Paper style={{backgroundColor: 'white', maxHeight: 320, overflow: 'auto', borderRadius: 0 }}>
                  <List>
                    <ListItem style={{ justifyContent: 'center' }}>
                      <Button aria-controls='new-note' color='primary' variant='contained' onClick={() => setNewNoteModalOpen(true)} style={{ borderRadius: 4, width: '100%' }}>
                        <Create style={{ marginRight: '10%' }} />
                        <Typography style={{ color: 'white' }}>New Note</Typography>
                      </Button>
                    </ListItem>
                    <Divider />
                    {resource.notes.length > 0 ? resource.notes.map((note, index) => (
                      <React.Fragment key={index}>
                        <ListItem
                          selected={selectedNoteIndex === index}
                          onClick={(event) => handleListItemClick(event, index)}
                        >
                          <ListItemText 
                            primary={note.title}
                            secondary={new Date(note.updatedAt).toLocaleString()}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )) : null}
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={8}>
                <Paper style={{ backgroundColor: 'white', height: 320, marginRight: 12, borderRadius: 0 }}>
                  <IconButton aria-controls='archive-note' onClick={handleArchiveNote} style={{ marginLeft: '90%' }}>
                    <Delete />
                  </IconButton>
                  <Typography style={{ marginLeft: 30 }} variant='h4'>{resource.notes.length > 0 ? resource.notes[selectedNoteIndex].title : null}</Typography>
                  <Typography style={{ marginLeft: 30, opacity: 0.5 }} variant='subtitle2'>{resource.notes.length > 0 ? resource.notes[selectedNoteIndex].userID : null}</Typography>
                  <Typography style={{ marginLeft: 30, opacity: 0.5 }} variant='subtitle2'>
                    {resource.notes.length > 0 ? new Date(resource.notes[selectedNoteIndex].updatedAt).toLocaleString() : null}
                  </Typography>
                  <Typography style={{ marginLeft: 30, maxHeight: 180, overflow: 'auto' }} variant='body2'>{resource.notes.length > 0 ? resource.notes[selectedNoteIndex].body : null}</Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={newNoteModalOpen}
          onClose={() => setNewNoteModalOpen(false)}
          aria-labelledby='new-note-modal'
          aria-describedby='modal-for-adding-new-note'
          maxWidth='md'
          PaperProps={{ style: { backgroundColor: '#F7F7F7', width: '100%' } }}
        >
          <DialogContent style={{ backgroundColor: '#FFFFFF', margin: 24 }}>
            <Grid container spacing={1} alignItems='flex-end'>
              <Grid item>
                <Label />
              </Grid>
              <Grid item style={{ width: '90%' }} >
                <TextField
                  autoFocus
                  margin='dense'
                  id='title'
                  label='Title'
                  type='text'
                  fullWidth
                  value={newNoteTitle}
                  onChange={handleNewNoteTitleChange}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent style={{ backgroundColor: '#FFFFFF', margin: 24 }}>
            <Grid container spacing={1} alignItems='flex-end'>
              <Grid item>
                <Subject />
              </Grid>
              <Grid item style={{ width: '90%' }} >
                <TextField
                  margin='dense'
                  id='body'
                  label='Body'
                  type='text'
                  fullWidth
                  value={newNoteBody}
                  onChange={handleNewNoteBodyChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Grid style={{ marginLeft: 'auto' }}>
            <Button
              aria-controls='cancel-new-note-modal'
              variant='contained'
              onClick={() => setNewNoteModalOpen(false)}
              style={{ borderRadius: 4, marginBottom: 24, marginRight: 24 }}
            >
              <Cancel />
              <Typography>Cancel</Typography>
            </Button>
            <Button
              aria-controls='save-new-note'
              color='primary'
              variant='contained'
              onClick={handleSaveNewNote}
              style={{ borderRadius: 4, marginBottom: 24, marginRight: 24, width: 100 }}
            >
              {
                !saveNewNoteLoading && 
                <React.Fragment>
                  <Save />
                  <Typography style={{ color: 'white' }}>Save</Typography>
                </React.Fragment>
              }
              { saveNewNoteLoading && <CircularProgress color='info' size={24} /> }
            </Button>
          </Grid>
        </Dialog>
        <Snackbar open={errorMessage ? true : false} autoHideDuration={2500}>
          <Alert severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
}