import React from 'react';

import { Router, LocationProvider, Redirect } from '@reach/router';

import SignIn from './Authentication/sign-in.js';
import ResourcesOverview from './Resources/resources-overview.js'
import Sites from './Sites/sites.js'
import Alerts from './Alerts/alerts.js'
import ResourceDetails from './Resources/components/resource-details.js';
import ResourceAnalysis from './Resources/components/resource-analysis.js';
import AuthenticationContext, { AuthConsumer } from '../shared/contexts/authenticationContext';
import ResetPassword from './Authentication/reset-password.js';
import Reporting from './Reporting/reporting.js';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return( 
    <AuthConsumer>
      {
        (value) => {
          console.log("ProtectedRoute user: ", value.user);
          // logger.debug("Redirecting from component: ", Component);
          return (value.user ? <Component {...rest} /> : <Redirect from="" to="/sign-in" noThrow/>);
        }
      }
    </AuthConsumer>
  );
};

const PublicRoute = ({ component: Component, ...rest }) => (
  <Component {...rest} />
);

export default function AppRouter(props) {
  return(
    <LocationProvider>
      <Router>
        <PublicRoute path="/sign-in" component={SignIn}/>
        <PublicRoute path="/reset-password" component={ResetPassword}/>
        <ProtectedRoute path="/" component={ResourcesOverview}/>
        <ProtectedRoute path="/sites" component={Sites}/>
        <ProtectedRoute path="/reports" component={Reporting}/>
        <ProtectedRoute path="/alerts" component={Alerts}/>
        <ProtectedRoute path="/resource/:loggerID" component={ResourceDetails}/>
        <ProtectedRoute path="/resource/:loggerID/analysis" component={ResourceAnalysis}/>
      </Router>
    </LocationProvider>
  );
}