import React, { useContext } from 'react';
import { Box, Drawer, List, ListItem, ListItemText, ListItemIcon, IconButton, Typography, makeStyles, Divider } from '@material-ui/core';
import { Description, ExitToApp, Home, LocationOn, Notifications } from '@material-ui/icons';
import { Auth } from "aws-amplify";
import { navigate } from '@reach/router';

import AuthenticationContext from "../../shared/contexts/authenticationContext";
import { globalDataStore } from '../../shared/contexts/globalDataContext';

import novionLogoImage from '../../assets/images/novion-logo-white-wordmark.png';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 240,
    backgroundColor: '#4050B5',
  },
}));

function handleActiveDrawerItemChange(text) {
  switch (text) {
    case 'Home':
      navigate('/');
      break;
    case 'Sites':
      navigate('/sites'); 
      break;
    case 'Reporting':
      navigate('/reports'); 
      break;
    case 'Alerts':
      navigate('/alerts');
      break;
    default:
      break;
  }
};

export default function Navigator(props) {
  const classes = useStyles();

  const authContext = useContext(AuthenticationContext);
  const updateCurrentUser = authContext.updateCurrentUser;

  const { state, dispatch } = useContext(globalDataStore);
  
  return (
    <div style={{flexGrow: 1}}>
      <Drawer
        classes={{
          paper: classes.drawerPaper
        }}
        variant="permanent"
        anchor="left"
      >
        <Box height="10vh" display="flex" justifyContent='center' alignItems='center'>
          <img className="logo" src={novionLogoImage} alt="Resource Managent Platform by Novion" height='70%'/>
        </Box>
        <Divider />
        <List>
          {['Home', 'Sites', 'Reporting', 'Alerts'].map((text, index) => (
            <ListItem selected={props.activeDrawerItem === text} button key={text} style={{marginLeft: 15, width: 210, borderRadius: 8}} onClick={() => handleActiveDrawerItemChange(text)}>
              <ListItemIcon><DrawerIcon text={text} /></ListItemIcon>
              <ListItemText primary={text} style={{color: 'white'}} />
            </ListItem>
          ))}
        </List>
        <div style={{height: '60%'}}/>
        <IconButton
          style={{ color: 'white', marginLeft: 15, width: 210, borderRadius: 8}}
          onClick={
            () => {
              dispatch({type:"cleanup", payload: null});

              Auth.signOut()
              .catch((err) => console.log('Auth signout error: ', err))
              .then((data) => {
                console.log('signout worked', data);
                updateCurrentUser(null);
                // navigate('/sign-in');
              });
            }
          }
        >
          <ExitToApp style={{ marginRight: 10 }}/>
          <Typography>Sign Out</Typography>
        </IconButton>
      </Drawer>
    </div>
  );
}

function DrawerIcon(props) {
  const drawerText = props.text;

  switch (drawerText) {
    case 'Home':
      return <Home style={{color: 'white'}} />;
    case 'Sites':
      return <LocationOn style={{color: 'white'}} />;
    case 'Reporting':
        return <Description style={{color: 'white'}} />; 
    case 'Alerts':
      return <Notifications style={{color: 'white'}} />;
    default:
      return null;
  }
}
