import React, { useState } from 'react';
import { Typography, TextField, Grid, Box, Button, Snackbar, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { navigate } from '@reach/router';
import { Auth } from "aws-amplify";

import novionLogoImage from '../../assets/images/novion-logo-white-wordmark.svg';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ResetPassword(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');

  const onChange = (key, value) => {
    setErrorMessage(null);
    if (key === "email") {
      setEmail(value);
    } else if (key === "code") {
      setConfirmationCode(value);
    } else if (key === "password") {
      setPassword(value);
    }
  }

  function handleBackToSignin() {
    navigate('/sign-in');
  }

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendCode();
    }
  };

  function sendCode() {
    setIsLoading(true);
    Auth.forgotPassword(email.toLowerCase())
    .then(() => {
      setShowConfirmation(true);
      setIsLoading(false);
    })
    .catch((err) => {
      console.error("Send Code error: ", err);
      if (err.code === 'UserNotFoundException') {
        setErrorMessage("User with this email not found");
      }
      else if (err.code === 'InvalidParameterException') {
        setErrorMessage("Invalid email address");
      }
      else {
        setErrorMessage(`${err.code}: ${err.message}`);
      }
    });
  }

  const handleEnterKeyForReset= (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      resetPasswordSubmit();
    }
  };

  function resetPasswordSubmit() {
    setIsLoading(true);
    Auth.forgotPasswordSubmit(email.toLowerCase(), confirmationCode, password)
    .then(() => {
      setSuccessMessage("Successfully changed your password! Navigating back to Sign in...");
      setTimeout(() => navigate("/sign-in"), 2500);
    })
    .catch((err) => {
      console.error("Reset Password Submit error: ", err);
      setErrorMessage(err.message);
    });
  }

  return(
    <Grid container direction='row' style={{maxWidth: '98%'}}>
      <Grid item style={{width: '50%'}}>
        <Box height="100vh" display="flex" justifyContent='center' alignItems='center' style={{background: 'linear-gradient(180deg, #4350AF 22.66%, #4584D8 100%)'}}>
          <img className="logo" src={novionLogoImage} alt="Resource Managent Platform by Novion" height='70%'/>
        </Box>
      </Grid>
      <Grid item style={{width: '50%', marginTop: 'auto', marginBottom: 'auto'}}>
        {!showConfirmation && (
          <React.Fragment>
            <Typography style={{marginLeft: '25%', /* marginRight: 50 */}} variant="h5">Reset Password</Typography>
            <form noValidate autoComplete="off">
              <Box style={{marginLeft: '25%', marginRight: '25%'}} my={4}>
                <TextField label="Email " required type="email" fullWidth onChange={event => onChange('email', event.target.value)} onKeyDown={event => handleEnterKey(event)} margin="normal" size="medium"/>
              </Box>
              <Grid container>
                <Grid item style={{marginLeft: '25%', width: '100%', marginRight: '25%'}}>
                  <Button color='primary' variant='contained' onClick={sendCode} fullWidth>
                    {!isLoading && 'Email Confirmation Code'}
                    {isLoading && <CircularProgress color='info' size={24} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Button
              style={{ marginLeft: '25%', marginTop: 12, fontSize: 12, textTransform: 'none' }}
              onClick={handleBackToSignin}
            >
              Back to Sign In
            </Button>
          </React.Fragment>
        )}
        {showConfirmation && (
          <React.Fragment>
            <Typography style={{marginLeft: '25%', /* marginRight: 50 */}} variant="h5">Create new password</Typography>
            <form noValidate autoComplete="off">
              <Box style={{marginLeft: '25%', marginRight: '25%'}} my={4}>
                <TextField label="Confirmation Code " required type="text" fullWidth onChange={event => onChange('code', event.target.value)} margin="normal" size="medium"/>
              </Box>
              <Box style={{marginLeft: '25%', marginRight: '25%'}} my={4}>
                <TextField label="New Password " required type="password" fullWidth onChange={event => onChange('password', event.target.value)} onKeyDown={event => handleEnterKeyForReset(event)} margin="normal" size="medium"/>
              </Box>
              <Grid container>
                <Grid item style={{marginLeft: '25%', width: '100%', marginRight: '25%'}}>
                  <Button color='primary' variant='contained' onClick={resetPasswordSubmit} fullWidth>
                    {!isLoading && 'Submit'}
                    {isLoading && <CircularProgress color='info' size={24} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Button
              style={{ marginLeft: '25%', marginTop: 12, fontSize: 12, textTransform: 'none' }}
              onClick={sendCode}
            >
              Resend Confirmation Code
            </Button>
          </React.Fragment>
        )}
        <Snackbar open={errorMessage ? true : false} autoHideDuration={2500}>
          <Alert severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={successMessage ? true : false}>
          <Alert severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}
