export function getLiters(timestampString, rawSensorValue) {
    if (new Date(timestampString).getTime() >= new Date('2022-09-29T20:10:07.000Z').getTime()){
        return (parseFloat(rawSensorValue) + 10851 + 202709 + 300103 + 86 + 4416722 + 127928 + 59901 + 1349233 + 20400578 + 4064832) / 660;
    }
    if (new Date(timestampString).getTime() >= new Date('2022-09-16T01:40:55.000Z').getTime()){
        return (parseFloat(rawSensorValue) + 202709 + 300103 + 86 + 4416722 + 127928 + 59901 + 1349233 + 20400578 + 4064832) / 660;
    }
    if (new Date(timestampString).getTime() >= new Date('2022-09-07T16:56:47.000Z').getTime()){
        return (parseFloat(rawSensorValue) + 300103 + 86 + 4416722 + 127928 + 59901 + 1349233 + 20400578 + 4064832) / 660;
    }
    if (new Date(timestampString).getTime() >= new Date('2022-07-18T02:30:54.000Z').getTime()){
        return (parseFloat(rawSensorValue) + 86 + 4416722 + 127928 + 59901 + 1349233 + 20400578 + 4064832) / 660;
    }
    else if (new Date(timestampString).getTime() >= new Date('2022-07-13T18:34:00.000Z').getTime()){
        return (parseFloat(rawSensorValue) + 4416722 + 127928 + 59901 + 1349233 + 20400578 + 4064832) / 660;
    }
    else if (new Date(timestampString).getTime() >= new Date('2022-07-08T15:24:46.000Z').getTime()){
        return (parseFloat(rawSensorValue) + 127928 + 59901 + 1349233 + 20400578 + 4064832) / 660;
    }  
    else if (new Date(timestampString).getTime() >= new Date('2022-07-08T11:06:35.000Z').getTime()){
        return (parseFloat(rawSensorValue) + 59901 + 1349233 + 20400578 + 4064832) / 660;
    }
    else if (new Date(timestampString).getTime() >= new Date('2022-07-08T09:02:54.000Z').getTime()){
        return (parseFloat(rawSensorValue) + 1349233 + 20400578 + 4064832) / 660;
    }
    else if (new Date(timestampString).getTime() >= new Date('2022-07-06T17:10:22.000Z').getTime()){
        return (parseFloat(rawSensorValue) + 20400578 + 4064832) / 660;
    }
    else if (new Date(timestampString).getTime() >= new Date('2022-06-09T23:51:04.000Z').getTime()){
        return (parseFloat(rawSensorValue) + 4064832) / 660;
    }
    else {
        return parseFloat(rawSensorValue) / 660;
    }
}
