import React, { useState, useEffect, useContext } from 'react';
import { Paper, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Button, Menu, MenuItem, FormControlLabel, Switch } from '@material-ui/core';
import { LocationOn, FiberManualRecord, ViewColumn, AssessmentRounded } from '@material-ui/icons';
import { VictoryScatter, VictoryLine } from 'victory';
import { navigate } from '@reach/router';

import Navigator from '../../shared/components/navigator';
import Footer from '../../shared/components/footer';
import { globalDataStore } from '../../shared/contexts/globalDataContext';

function createData(site, sensor, reading, date, time) {
  return { site, sensor, reading, date, time };
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export default function Sites (props) {
  const [columnsMenuAnchorElement, setColumnsMenuAnchorElement] = useState(false);
  const [showColumnSite, setShowColumnSite] = useState(true);
  const [showColumnSensor, setShowColumnSensor] = useState(true);
  const [showColumnReading, setShowColumnReading] = useState(true);
  const [showColumnDate, setShowColumnDate] = useState(true);
  const [showColumnTime, setShowColumnTime] = useState(true);
  const [showColumnLast24Hours, setShowColumnLast24Hours] = useState(true);
  const [showColumnStatus, setShowColumnStatus] = useState(true);

  const {state, dispatch} = useContext(globalDataStore);

  function handleColumnsButtonClick(event) {
    setColumnsMenuAnchorElement(event.target);
  }

  function handleColumnsMenuClose() {
    setColumnsMenuAnchorElement(false);
  }

  // console.log(state.resourceDetails);

  return (
    <div style={{flexGrow: 1, marginLeft: 240, backgroundColor: '#F7F7F7'}}>
      <Navigator activeDrawerItem='Sites' />
      <Grid style={{margin: 24, width: '95%'}}>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50}} alignItems='center'>
          <Grid item style={{marginLeft: 10, marginRight: 10}}>
            <LocationOn fontSize='large' color='primary' />
          </Grid>
          <Grid item >
            <Typography variant='body1' style={{fontWeight: 'bold'}}>Sites</Typography>
          </Grid>
        </Grid>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50, marginTop: 14}} alignItems='center'>
          <Grid style={{marginLeft: 10}} container item justifyContent='space-between' alignItems='center'>
            <IconButton aria-controls='select-columns' aria-haspopup='true' onClick={handleColumnsButtonClick} style={{ borderRadius: 8 }}>
              <ViewColumn color='primary' />
              <Typography color='textPrimary' >Columns</Typography>
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={columnsMenuAnchorElement}
              keepMounted
              open={Boolean(columnsMenuAnchorElement)}
              onClose={handleColumnsMenuClose}
            >
              <MenuItem>
                <FormControlLabel
                  control={<Switch checked={showColumnSite} onChange={() => setShowColumnSite(!showColumnSite)} color='primary' />}
                  label='Site'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={<Switch checked={showColumnSensor} onChange={() => setShowColumnSensor(!showColumnSensor)} color='primary' />}
                  label='Sensor'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={<Switch checked={showColumnReading} onChange={() => setShowColumnReading(!showColumnReading)} color='primary' />}
                  label='Reading'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={<Switch checked={showColumnDate} onChange={() => setShowColumnDate(!showColumnDate)} color='primary' />}
                  label='Date'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={<Switch checked={showColumnTime} onChange={() => setShowColumnTime(!showColumnTime)} color='primary' />}
                  label='Time'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={<Switch checked={showColumnLast24Hours} onChange={() => setShowColumnLast24Hours(!showColumnLast24Hours)} color='primary' />}
                  label='Last 24 hours'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={<Switch checked={showColumnStatus} onChange={() => setShowColumnStatus(!showColumnStatus)} color='primary' />}
                  label='Status'
                />
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table /* size='small' */ stickyHeader aria-label='Sites Table'>
            <TableHead>
              <TableRow selected>
                {showColumnSite && <TableCell>Site</TableCell>}
                {showColumnSensor && <TableCell align='left'>Sensor</TableCell>}
                {showColumnReading && <TableCell align='left'>Reading</TableCell>}
                {showColumnDate && <TableCell align='left'>Date</TableCell>}
                {showColumnTime && <TableCell align='left'>Time</TableCell>}
                {showColumnLast24Hours && <TableCell align='left'>Last 24 hours</TableCell>}
                {showColumnStatus && <TableCell align='left'>Status</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.resourceDetails.map((resourceDetail) => (
                <TableRow key={resourceDetail.name} hover onClick={() => navigate(`/resource/${resourceDetail.loggerID}`, { state: { name: resourceDetail.name } })} >
                  {showColumnSite && <TableCell component='th' scope='row'>{resourceDetail.name}</TableCell>}
                  {showColumnSensor && <TableCell align='left'>{resourceDetail.sensor}</TableCell>}
                  {showColumnReading && <TableCell align='left'>{`${resourceDetail.latestValue} ${resourceDetail.readingUnitSuffix}`}</TableCell>}
                  {showColumnDate && <TableCell align='left'>{new Date(resourceDetail.timestamp).toLocaleDateString()}</TableCell>}
                  {showColumnTime && <TableCell align='left'>{new Date(resourceDetail.timestamp).toLocaleTimeString()}</TableCell>}
                  {
                    showColumnLast24Hours && <TableCell align='left'>
                      <svg width={100} height={20} style={{ marginRight: 24}}>
                        <VictoryLine
                          standalone={false}
                          width={100} height={20} padding={0}
                          data={resourceDetail.last24Hours.map(item => {return {x: new Date(item.eventTimeStamp), y: item.reading * 0.704944599}})}
                          domain={{ y: [resourceDetail.chartMinValue, resourceDetail.chartMaxValue] }}
                        />
                        {/* <VictoryLine
                          standalone={false}
                          width={100} height={20} padding={0}
                          data={straightLineData}
                          style={{
                            data: { stroke: '#4050B5', strokeDasharray: '4' },
                          }}
                        />
                        <VictoryScatter
                          standalone={false}
                          width={100} height={20} padding={0}
                          data={sampleData}
                          style={{
                            data: { fill: '#4050B5' },
                          }}
                        /> */}
                      </svg>
                    </TableCell>
                  }
                  {showColumnStatus && <TableCell align='left'><FiberManualRecord fontSize='small' style={{color: resourceDetail.online ? '#59AF43' : '#434343'}}/></TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}
