import React from 'react';
import { VictoryChart, VictoryTheme, VictoryAxis, VictoryLine, createContainer, VictoryArea, VictoryScatter } from 'victory';

export default function ResourceChart(props) {
  const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi');

  return (
    <VictoryChart
      width={900}
      scale={{ x: 'time', y: 'linear' }}
      theme={VictoryTheme.grayscale}
      containerComponent={
        <VictoryZoomVoronoiContainer 
          labels={({ datum }) => 
            Object.prototype.toString.call(datum.x) === '[object Date]' ? `${datum.x.getFullYear()}-${datum.x.getMonth() + 1}-${datum.x.getDate()}\r\n${datum.x.getHours()}:${datum.x.getMinutes()}\r\n${datum.y}` : 'n/a'
          }
        />
      }
      // padding={{ left: 90, top: 50, right: 50, bottom: 50 }}
    >
      {
        props.excludedEvents ? props.excludedEvents.map(excludedEvent => (
          <VictoryArea
            style={{ data: { fill: "#f9a19a" } }}
            data={[
              { x: new Date(excludedEvent.startTimestamp), y: props.domain[1], y0: props.domain[0] },
              { x: new Date(excludedEvent.endTimestamp), y: props.domain[1], y0: props.domain[0] }
            ]}
          />
        )) : null
      }
      {
        props.includedEvents ? props.includedEvents.map(includedEvent => (
          <VictoryArea
            style={{ data: { fill: "#90ee90" } }}
            data={[
              { x: new Date(includedEvent.startTimestamp), y: props.domain[1], y0: props.domain[0] },
              { x: new Date(includedEvent.endTimestamp), y: props.domain[1], y0: props.domain[0] }
            ]}
          />
        )) : null
      }
      <VictoryAxis
        fixLabelOverlap
      />
      <VictoryAxis
        dependentAxis
        label={props.unitLabel}
        style={{axisLabel: {padding: 40}}}
        domain={props.domain}
      />
      <VictoryLine
        style={{
          data: { stroke: '#5e96f4' },
          parent: { border: '1px solid #ccc'}
        }}
        data={props.chartData}
      />
      {
        props.points ? props.points.map(point => (
          <VictoryScatter
            style={{ data: { fill: "#133368" } }}
            // size={7}
            data={props.points}
          />
        )) : null
      }
    </VictoryChart>
  );
}
