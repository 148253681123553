export function getAlertCategory(measureCode) {
  let alertCategory = 'N/A';

  if (measureCode === '0') {
    alertCategory = 'Water Level';
  }
  else if (measureCode === '1') {
    alertCategory = 'Sensor Battery Level';
  }
  else if (measureCode === '2') {
    alertCategory = 'Logger Battery Level';
  }

  return alertCategory;
}

export function getMeasureCode(alertCategory) {
  let measureCode = '0';

  if (alertCategory === 'Water Level') {
    measureCode = '0';
  }
  else if (alertCategory === 'Sensor Battery Level') {
    measureCode = '1';
  }
  else if (alertCategory === 'Logger Battery Level') {
    measureCode = '2';
  }

  return measureCode;
}

export function findResourcePropertyValue(matchingLoggerID, resources, propertyName) {
  let resourcePropertyValue = null;

  const matchingResource = resources.find(resource => resource.loggerID === matchingLoggerID);
  if (matchingResource != null) {
    resourcePropertyValue = matchingResource[propertyName];
  }

  return resourcePropertyValue;
}

export function findResource(matchingLoggerID, resources, resourceName=null) {
  let matchingResource = null;

  if (resourceName == null) {
    matchingResource = resources.find(resource => resource.loggerID === matchingLoggerID);
  }
  else {
    matchingResource = resources.find(resource => resource.loggerID === matchingLoggerID && resource.name === resourceName);
  }
  
  return matchingResource;
}
