import { API } from "aws-amplify";
import * as customQueries from '../../graphql/custom-queries';

export async function getAllLoggersForUser(userEmail) {
  // let nowMinus24Hours = new Date();
  // nowMinus24Hours.setHours(nowMinus24Hours.getHours() - 24);

  const queryResult = await API.graphql(
    {
      query: customQueries.accessRecordUserByUser,
      variables: {userID: userEmail }
    }
  )
  .catch(e => console.log("error in getAllLoggersForUser", e));
  const accessRecords = queryResult.data["accessRecordUserByUser"].items;

  let loggersForUser = [];
  accessRecords.forEach(accessRecordItem => {
    accessRecordItem.accessRecord.accessRecordLoggers.items.forEach(accessRecordLogger => {
      loggersForUser.push(accessRecordLogger.logger);
    })
  });

  return loggersForUser;
}
