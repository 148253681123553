import { getLiters } from "./get-liters";
import getReadingUnitSuffix from "./get-reading-unit-suffix";
import getTvocFromVocIndex from "./get-tvoc";

const AIR_QUALITY_SUB_SENSORS = [
  'PM1.0',
  'PM2.5',
  'PM4.0',
  'PM10.0',
  'Humidity',
  'VOC'
];

const IMU_SUB_SENSORS = [
  'Roll',
  'Pitch',
  'Yaw',
];

export function mapLoggerItemToResource(loggerItem) {
  let resource = {
    loggerID: null,
    online: null,
    name: null,
    loggerSerialNumber: null,
    sensor: null,
    sensorID: null,
    latestValue: null,
    timestamp: null,
    sensorModel: null,
    batteryLevel: null,
    latitude: null,
    longitude: null,
    monitored: null,
    siteType: null,
    readingUnitSuffix: null,
    chartMinValue: null, 
    chartMaxValue: null,
  };

  resource.loggerID = loggerItem.id;
  resource.name = loggerItem.siteName;
  resource.loggerSerialNumber = loggerItem.serialNumber;
  resource.sensor = loggerItem.sensors.items[0].type;
  resource.sensorID = loggerItem.sensors.items[0].id;
  resource.latestValue = (loggerItem.sensors.items[0].events.items[0].reading * 0.704944599).toFixed(3);
  resource.timestamp = loggerItem.sensors.items[0].events.items[0].eventTimeStamp;
  resource.sensorModel = loggerItem.sensors.items[0].name;
  resource.batteryLevel = getBatteryLevel(loggerItem.events.items[0].batteryVoltageV)/* batteryLevelPercent */;
  resource.latitude = loggerItem.siteLatitudeDegrees;
  resource.longitude = loggerItem.siteLongitudeDegrees;
  resource.monitored = loggerItem.monitored === '1';
  resource.last24Hours = loggerItem.sensors.items[0].events.items;
  resource.online = (
    new Date(Date.now()).getTime() - 
    new Date(loggerItem.sensors.items[0].events.items[0].eventTimeStamp).getTime()
  ) < 14400000; // 14400000 ms = 4 hours
  resource.notes = loggerItem.notes.items.length > 0 ? loggerItem.notes.items : [];
  
  if (resource.sensor === 'Water Level') {
    resource.siteType = 'GI';
    resource.readingUnitSuffix = getReadingUnitSuffix(resource.sensor);
    resource.chartMinValue = 0;
    resource.chartMaxValue = 1;
  }
  else if (resource.sensor === 'Water Flow Sensor') {
    resource.siteType = resource.sensor;
    resource.readingUnitSuffix = getReadingUnitSuffix(resource.sensor);
    resource.chartMinValue = 0;
    resource.chartMaxValue = null;

    resource.latestValue = getLiters (
      loggerItem.sensors.items[0].events.items[0].eventTimeStamp,
      loggerItem.sensors.items[0].events.items[0].reading
    ).toFixed(3);
  }
  else if (resource.sensor === 'Air Quality') {
    resource.batteryLevel = `${loggerItem.events.items[0].batteryLevelPercent} %`;

    let subResources = [];
    AIR_QUALITY_SUB_SENSORS.forEach((subResourceName, subResourceIndex) => {
      if (subResourceName === 'PM1.0' || subResourceName === 'PM4.0') {
        // skip
      }
      else {
        let subResource = JSON.parse(JSON.stringify(resource));
        subResource.name = resource.name + ' - ' + subResourceName;
        subResource.sensor = resource.sensor + ' - ' + subResourceName;
        subResource.siteType = resource.sensor;
        subResource.readingUnitSuffix = getReadingUnitSuffix(subResource.sensor);
        subResource.chartMinValue = 0;
        subResource.chartMaxValue = getAirQualitySubResourceChartMaxValue(subResourceName);

        subResource.latestValue = loggerItem.sensors.items[0].events.items[0].reading.split(';')[subResourceIndex];
        subResource.last24Hours.forEach(last24HoursItem => last24HoursItem.reading = last24HoursItem.reading.split(';')[subResourceIndex]);

        if (subResourceName === 'VOC') {
          subResource.latestValue = (getTvocFromVocIndex(subResource.latestValue)).toFixed(3);
          subResource.last24Hours.forEach(last24HoursItem => last24HoursItem.reading = (getTvocFromVocIndex(last24HoursItem.reading)).toFixed(3));
        }

        subResources.push(subResource);
      }
    });

    resource = subResources;
  }
  else if (resource.sensor === 'IMU') {
    resource.batteryLevel = `${loggerItem.events.items[0].batteryLevelPercent} %`;

    let subResources = [];
    IMU_SUB_SENSORS.forEach((subResourceName, subResourceIndex) => {
      let subResource = JSON.parse(JSON.stringify(resource));
      subResource.name = resource.name + ' - ' + subResourceName;
      subResource.sensor = resource.sensor + ' - ' + subResourceName;
      subResource.siteType = resource.sensor;
      subResource.readingUnitSuffix = getReadingUnitSuffix(subResource.sensor);
      subResource.chartMinValue = -90;
      subResource.chartMaxValue = 90;

      subResource.latestValue = loggerItem.sensors.items[0].events.items[0].reading.split(';')[subResourceIndex];
      subResource.last24Hours.forEach(last24HoursItem => last24HoursItem.reading = last24HoursItem.reading.split(';')[subResourceIndex]);

      subResources.push(subResource);
    });

    resource = subResources;
  }
  else if (resource.sensor === 'Ambient Light') {
    resource.batteryLevel = `${loggerItem.events.items[0].batteryLevelPercent} %`;

    resource.siteType = resource.sensor;
    resource.readingUnitSuffix = getReadingUnitSuffix(resource.sensor);
    resource.chartMinValue = 0;
    resource.chartMaxValue = 200000;

    resource.latestValue = loggerItem.sensors.items[0].events.items[0].reading;
    resource.last24Hours = loggerItem.sensors.items[0].events.items;
  }

  return resource;
}

function getBatteryLevel(batteryVoltageV) {
  let batteryLevel = 'n/a';

  if (batteryVoltageV > 3.9) {
    batteryLevel = 'High';
  }
  else if ( (batteryVoltageV <= 3.9) && (batteryVoltageV >= 3.6) ) {
    batteryLevel = 'Medium';
  }
  else if ( batteryVoltageV < 3.6 ) {
    batteryLevel = 'Low';
  }
  
  return batteryLevel;
}

function getAirQualitySubResourceChartMaxValue(sensor) {
  let airQualitySubResourceChartMaxValue = null;

  switch (sensor) {
    case 'PM1.0':
      airQualitySubResourceChartMaxValue = 100;
      break;
    case 'PM2.5':
      airQualitySubResourceChartMaxValue = 100;
      break;
    case 'PM4.0':
      airQualitySubResourceChartMaxValue = 100;
      break;
    case 'PM10.0':
      airQualitySubResourceChartMaxValue = 100;
      break;
    case 'Humidity':
      airQualitySubResourceChartMaxValue = 100;
      break;
    case 'VOC':
      airQualitySubResourceChartMaxValue = 2384;
      break;
  
    default:
      break;
  }

  return airQualitySubResourceChartMaxValue;
}
