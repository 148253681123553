import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Paper, Typography, TextField, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Button, Menu, MenuItem, FormControlLabel, Switch, Dialog, DialogContent, Input, Select, Checkbox, ListItemText, Chip, Snackbar, CircularProgress, FormGroup, Box } from '@material-ui/core';
import { Delete, Description, LocationOn, SystemUpdateAlt } from '@material-ui/icons';

import Navigator from '../../shared/components/navigator';
import { globalDataStore } from '../../shared/contexts/globalDataContext';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJS from '@date-io/dayjs';
import dayjs from 'dayjs';
import { asyncForEach, getArrayAvgValue, getArrayMaxValue, getArrayMinValue } from '../../shared/utils/arrays';
import { getSensorEventSeries } from '../../shared/api-calls/sensor-api-calls';
import getTvocFromVocIndex from '../../shared/utils/get-tvoc';
import { getLiters } from '../../shared/utils/get-liters';
import FileSaver from 'file-saver';
import { getFloodEvents } from '../../shared/api-calls/flood-event-api-calls';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },  
  },
};

export default function Reporting (props) {
  const [ selectedSitesResourceDetails, setSelectedSitesResourceDetails ] = useState([]);
  const [ sensorDataChecked, setSensorDataChecked ] = useState(false);
  const [ sensorReportChecked, setSensorReportChecked ] = useState(false);
  const [ activityLogChecked, setActivityLogChecked ] = useState(false);
  const [ startDate, setStartDate ] = useState(new Date());
  const [ endDate, setEndDate ] = useState(new Date());
  const [ loading , setLoading ] = useState(false);

  const {state, dispatch} = useContext(globalDataStore);
  
  const handleSelectedSitesChange = (event) => {
    setSelectedSitesResourceDetails(event.target.value);
  };

  const handleClearAll = () => {
    setSelectedSitesResourceDetails([]);
    setSensorDataChecked(false);
    setSensorReportChecked(false);
    setActivityLogChecked(false);
  };

  async function saveFiles() {
    setLoading(true);

    const startDateTime = startDate;
    startDateTime.setHours(0, 0, 0, 0);
    const endDateTime = endDate;
    endDateTime.setHours(23, 59, 59, 999);

    const dateString = `${startDate.toDateString()} to ${endDate.toDateString()}`;

    if (sensorDataChecked) {
      await asyncForEach(selectedSitesResourceDetails, async selectedSiteResourceDetail => {
        if (selectedSiteResourceDetail != null) {
          let sensorEventItems = await getSensorEventSeries(selectedSiteResourceDetail.sensorID, startDateTime.toISOString(), endDateTime.toISOString());
          // extract air quality data
          if (selectedSiteResourceDetail.sensor === 'Air Quality - PM1.0') {
            sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[0]);
          }
          else if (selectedSiteResourceDetail.sensor === 'Air Quality - PM2.5') {
            sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[1]);
          }
          else if (selectedSiteResourceDetail.sensor === 'Air Quality - PM4.0') {
            sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[2]);
          }
          else if (selectedSiteResourceDetail.sensor === 'Air Quality - PM10.0') {
            sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[3]);
          }
          else if (selectedSiteResourceDetail.sensor === 'Air Quality - Humidity') {
            sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[4]);
          }
          else if (selectedSiteResourceDetail.sensor === 'Air Quality - VOC') {
            sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = (getTvocFromVocIndex(sensorEventItem.reading.split(';')[5])).toFixed(3));
          }
          else if (selectedSiteResourceDetail.sensor === 'IMU - Roll') {
            sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[0]);
          }
          else if (selectedSiteResourceDetail.sensor === 'IMU - Pitch') {
            sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[1]);
          }
          else if (selectedSiteResourceDetail.sensor === 'IMU - Yaw') {
            sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[2]);
          }

          // remove items that are zeros (invalid pressure sensor data)
          sensorEventItems = sensorEventItems.filter (sensorEventItem => {
            return parseFloat(sensorEventItem.reading) !== 0;
          });

          sensorEventItems = sensorEventItems.map (sensorEventItem => {
            const timeStampDate = new Date(sensorEventItem.eventTimeStamp);
            const timeStampMinuteMod = timeStampDate.getMinutes() % 5;
            const timeStampSeconds = timeStampDate.getSeconds();
            if (timeStampMinuteMod > 2 || (timeStampMinuteMod === 2 && timeStampSeconds >= 30)) {
              // round up
              timeStampDate.setMinutes(timeStampDate.getMinutes() - timeStampMinuteMod + 5);
              timeStampDate.setSeconds(0);
            } else {
              // round down
              timeStampDate.setMinutes(timeStampDate.getMinutes() - timeStampMinuteMod);
              timeStampDate.setSeconds(0);
            }
            
            if (selectedSiteResourceDetail.sensorID === '8') {
              return `${timeStampDate.toISOString()},${getLiters(sensorEventItem.eventTimeStamp, sensorEventItem.reading).toFixed(3)}\r\n`;
            }
            else if (selectedSiteResourceDetail.sensor.startsWith('Air Quality')) {
              return `${timeStampDate.toISOString()},${sensorEventItem.reading}\r\n`;
            }
            else {
              return `${timeStampDate.toISOString()},${(sensorEventItem.reading * 0.704944599).toFixed(3)}\r\n`;
            }
          });

          let index = 0;
          while (index < (sensorEventItems.length - 1)) {
            const currentSensorEventDate = new Date(sensorEventItems[index].split(',')[0]);
            const nextSensorEventDate = new Date(sensorEventItems[index + 1].split(',')[0]);

            const currentSensorEventReading = Number(sensorEventItems[index].split(',')[1]);
            const nextSensorEventReading = Number(sensorEventItems[index + 1].split(',')[1]);

            if ( (nextSensorEventDate.getTime() - currentSensorEventDate.getTime()) > 300000 ) {
              let interpolatedDate = currentSensorEventDate;
              interpolatedDate.setMinutes(currentSensorEventDate.getMinutes() + 5);
              const interpolatedReading = ((currentSensorEventReading + nextSensorEventReading) / 2).toFixed(3);
              const interpolatedSensorEvent = `${interpolatedDate.toISOString()},${interpolatedReading}\r\n`;
              
              sensorEventItems.splice(index + 1, 0, interpolatedSensorEvent);
            }

            if ( nextSensorEventDate.getTime() === currentSensorEventDate.getTime() ) {
              sensorEventItems.splice(index, 1);
              index--;
            }

            index++;
          }

          // keeping the timestamps in ISO format until now so that all time comparisons above are valid.
          let sensorEventsToDownload = sensorEventItems.map (sensorEventItem => {
            const sensorEventItemDate = new Date(sensorEventItem.split(',')[0]);
            const sensorEventItemReading = sensorEventItem.split(',')[1];
            return `${sensorEventItemDate.toLocaleDateString()} ${sensorEventItemDate.toLocaleTimeString()},${sensorEventItemReading}`;
          });

          sensorEventsToDownload.unshift(`Timestamp, ${selectedSiteResourceDetail.sensor} [${selectedSiteResourceDetail.readingUnitSuffix}]\r\n`);
          
          const blob = new Blob(sensorEventsToDownload, {type: "text/plain;charset=utf-8"});
          const fileName = `${selectedSiteResourceDetail.name} - ${dateString}.csv`;
          FileSaver.saveAs(blob, fileName);
        }
      });
    }

    if (sensorReportChecked || activityLogChecked) {
      let allSensorReports = [];
      let allFilteredFloodEvents = [];

      let siteNames = [];
      await asyncForEach(selectedSitesResourceDetails, async selectedSiteResourceDetail => {
        if (selectedSiteResourceDetail.siteType === 'GI') {
          siteNames.push(selectedSiteResourceDetail.name);

          let floodEventItems = await getFloodEvents(selectedSiteResourceDetail.sensorID, startDate.toISOString(), endDate.toISOString());

          let filteredFloodEventItems = floodEventItems.filter(floodEventItem => floodEventItem.excludeFromSensorReport === 'false');

          const floodEventsDurationHours = filteredFloodEventItems.map(floodEventItem => ( (new Date(floodEventItem.endTimestamp).getTime() - new Date(floodEventItem.startTimestamp).getTime()) / 3600000) );
          const floodEventsHeightM = filteredFloodEventItems.map(floodEventItem => floodEventItem.peakWaterLevelM);
          allSensorReports.push ( 
          {
            numFloodEvents: filteredFloodEventItems.length,
            avgFloodDurationHours: getArrayAvgValue(floodEventsDurationHours).toFixed(2),
            longestFloodDurationHours: getArrayMaxValue(floodEventsDurationHours).toFixed(2),
            shortestFloodDurationHours: getArrayMinValue(floodEventsDurationHours).toFixed(2),
            avgFloodHeightM: getArrayAvgValue(floodEventsHeightM).toFixed(3),
            avgDrawDownRateMmPerHour: getArrayAvgValue(filteredFloodEventItems.map(floodEventItem => floodEventItem.drawdownRateMmPerHour * -1)).toFixed(3),
            avgDrawDownDurationHours: getArrayAvgValue(filteredFloodEventItems.map(floodEventItem => floodEventItem.drawdownTimeSeconds / 3600)).toFixed(2),
          });

          allFilteredFloodEvents = allFilteredFloodEvents.concat (
            filteredFloodEventItems.map(filteredFloodEventItem => {
              return {
                'Site': selectedSiteResourceDetail.name,
                'Start Date': new Date(filteredFloodEventItem.startTimestamp).toLocaleDateString(),
                'Start Time': new Date(filteredFloodEventItem.startTimestamp).toLocaleTimeString(),
                'Flood Duration [hr]': ( (new Date(filteredFloodEventItem.endTimestamp).getTime() - new Date(filteredFloodEventItem.startTimestamp).getTime()) / 3600000).toFixed(2),
                'Drawdown Rate [mm/hr]': filteredFloodEventItem.peakWaterLevelM !== 'null' ? (filteredFloodEventItem.drawdownRateMmPerHour * -1).toFixed(3) : 'NaN',
                'Drawdown Time [hr]': (filteredFloodEventItem.drawdownTimeSeconds / 3600).toFixed(2),
                'Peak Water Level [m]': parseFloat(filteredFloodEventItem.peakWaterLevelM).toFixed(3),
              };
            })
          );
        }
      });

      if (sensorReportChecked) {
        let sensorReportDownloadData = [
          'Flood Events',
          'Avg Flood Duration [hr]',
          'Longest Flood Duration [hr]',
          'Shortest Flood Duration [hr]',
          'Avg Flood Height [m]',
          'Avg Drawdown Rate [mm/hr]',
          'Avg Drawdown Duration [hr]',
        ];
        allSensorReports.forEach(sensorReport => {
          sensorReportDownloadData[0] += `,${sensorReport.numFloodEvents}`;
          sensorReportDownloadData[1] += `,${sensorReport.avgFloodDurationHours}`;
          sensorReportDownloadData[2] += `,${sensorReport.longestFloodDurationHours}`;
          sensorReportDownloadData[3] += `,${sensorReport.shortestFloodDurationHours}`;
          sensorReportDownloadData[4] += `,${sensorReport.avgFloodHeightM}`;
          sensorReportDownloadData[5] += `,${sensorReport.avgDrawDownRateMmPerHour}`;
          sensorReportDownloadData[6] += `,${sensorReport.avgDrawDownDurationHours}`;
        });

        sensorReportDownloadData = sensorReportDownloadData.map(sensorReportDownloadDataItem => sensorReportDownloadDataItem += '\r\n');

        sensorReportDownloadData.unshift(`, ${siteNames.toString()}\r\n`);

        const blob = new Blob(sensorReportDownloadData, {type: "text/plain;charset=utf-8"});
        const fileName = `Sensor Report - ${dateString}.csv`;
        FileSaver.saveAs(blob, fileName);
      }

      if (activityLogChecked) {
        let activityLogDownloadData = [];

        allFilteredFloodEvents.forEach(floodEventItem => {
          activityLogDownloadData.push(`${Object.values(floodEventItem).toString()}\r\n`);
        });

        activityLogDownloadData.unshift(`${Object.keys(allFilteredFloodEvents[0]).toString()}\r\n`);

        const blob = new Blob(activityLogDownloadData, {type: "text/plain;charset=utf-8"});
        const fileName = `Activity Log - ${dateString}.csv`;
        FileSaver.saveAs(blob, fileName);
      }
    }

    setLoading(false);
  }

  return (
    <div style={{flexGrow: 1, marginLeft: 240, backgroundColor: '#F7F7F7'}}>
      <Navigator activeDrawerItem='Reporting' />
      <Grid style={{margin: 24, width: '95%'}}>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50}} alignItems='center'>
          <Grid item style={{marginLeft: 10, marginRight: 10}}>
            <Description fontSize='large' color='primary' />
          </Grid>
          <Grid item >
            <Typography variant='body1' style={{fontWeight: 'bold'}}>Reporting</Typography>
          </Grid>
        </Grid>
        <Grid style={{ backgroundColor: '#FFFFFF', marginTop: 24 }}>
          <Grid container alignItems='center'>
            <Grid item style={{ marginLeft: 24, marginTop: 24 }}>
              <LocationOn />
            </Grid>
            <Grid item style={{ marginTop: 24 }}>
              <Typography variant='body1' style={{fontWeight: 'bold'}}>
                Select sites for report
              </Typography>
            </Grid>
            <Button
              aria-controls='clear-all-sites'
              color='primary'
              size='small'
              onClick={handleClearAll}
              style={{ marginLeft: 'auto', marginRight: 24 }}
            >
              <Delete />
              <Typography>Clear All</Typography>
            </Button>
          </Grid>
          <Select
            multiple
            value={selectedSitesResourceDetails}
            onChange={handleSelectedSitesChange}
            input={<Input />}
            renderValue={(selected) => (
              <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                {selected.map((value, index) => (
                  <Chip key={index} label={value.name} style={{ margin: 2, borderRadius: 4 }} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            style={{ width: '95%', marginLeft: 24 }}
            variant='outlined'
          >
            {state.resourceDetails.map((resourceDetail, index) => (
              <MenuItem key={index} value={resourceDetail}>
                <Checkbox checked={selectedSitesResourceDetails.some(selectedSiteResourceDetail => selectedSiteResourceDetail.loggerID === resourceDetail.loggerID && selectedSiteResourceDetail.sensor === resourceDetail.sensor)} color='primary' />
                <ListItemText primary={resourceDetail.name} />
              </MenuItem>
            ))}
          </Select>
          <Grid container style={{ marginLeft: 24, marginTop: 24 }}>
            <Grid item xs={6}>
              <Typography  variant='body1' style={{fontWeight: 'bold'}}>
                Select parameters for report
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sensorDataChecked}
                      onChange={(event) => setSensorDataChecked(event.target.checked)}
                      color="primary"
                    />
                  }
                  label={"Sensor Data"}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sensorReportChecked}
                      onChange={(event) => setSensorReportChecked(event.target.checked)}
                      color="primary"
                    />
                  }
                  label={"Sensor Report"}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={activityLogChecked}
                      onChange={(event) => setActivityLogChecked(event.target.checked)}
                      color="primary"
                    />
                  }
                  label={"Activity Log"}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <Typography  variant='body1' style={{fontWeight: 'bold'}}>
                Select date range for report
              </Typography>
              <MuiPickersUtilsProvider utils={DayJS} >
                <KeyboardDatePicker
                  disableToolbar
                  autoOk 
                  disableFuture
                  minDate = {dayjs('2022-02-15T08:00:00.000Z')}
                  InputProps={{disableUnderline: true}}
                  format = "MMM D , YYYY"
                  inputstyle={{ textAlign: 'center'}}
                  value={startDate} 
                  style={{maxWidth: 180, marginLeft: 20, marginRight: 20}}
                  margin="normal" 
                  size="medium"
                  onChange={(date) => {setStartDate(date.toDate());}}
                  label={'Start Date'}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DayJS} >
                <KeyboardDatePicker
                  disableToolbar
                  autoOk 
                  disableFuture
                  minDate = {dayjs(startDate)}
                  InputProps={{disableUnderline: true}}
                  format = "MMM D , YYYY"
                  inputstyle={{ textAlign: 'center'}}
                  value={endDate} 
                  style={{maxWidth: 180, marginLeft: 20, marginRight: 20}}
                  margin="normal" 
                  size="medium"
                  onChange={(date) => {setEndDate(date.toDate());}}
                  label={'End Date'}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Box textAlign='center'>
            <IconButton>
              <Button disabled={ loading || selectedSitesResourceDetails.length === 0 } aria-controls='export' color='primary' variant='contained' onClick={() => saveFiles()} style={{ borderRadius: 4 }}>
                <SystemUpdateAlt style={{ marginRight: '10%' }} />
                <Typography style={{ color: 'white' }}>{loading ? 'Loading' : 'Export'}</Typography>
              </Button>
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
