export default function getTvocFromVocIndex(vocIndex) {
  let tvoc = (Math.log(501 - vocIndex) - 6.24)*(-381.97);

  return tvoc;
}

export function getVocIndexFromTvoc(tvoc) {
  let vocIndex = 501 - Math.exp( (tvoc/(-381.97)) + 6.24 );

  return vocIndex;
}
