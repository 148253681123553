export default function getReadingUnitSuffix(sensor) {
  let readingUnitSuffix = 'unit';

  switch (sensor) {
    case 'Water Level':
      readingUnitSuffix = 'm';
      break;
    case 'Water Flow Sensor':
      readingUnitSuffix = 'L';
      break;
    case 'Air Quality - PM1.0':
      readingUnitSuffix = 'ug/m3';
      break;
    case 'Air Quality - PM2.5':
      readingUnitSuffix = 'ug/m3';
      break;
    case 'Air Quality - PM4.0':
      readingUnitSuffix = 'ug/m3';
      break;
    case 'Air Quality - PM10.0':
      readingUnitSuffix = 'ug/m3';
      break;
    case 'Air Quality - Humidity':
      readingUnitSuffix = '% RH';
      break;
    case 'Air Quality - VOC':
      readingUnitSuffix = 'PPB';
      break;
    case 'IMU - Roll':
      readingUnitSuffix = 'deg';
      break;
    case 'IMU - Pitch':
      readingUnitSuffix = 'deg';
      break;
    case 'IMU - Yaw':
      readingUnitSuffix = 'deg';
      break;
    case 'Ambient Light':
      readingUnitSuffix = 'lux';
      break;

    default:
      console.error('No matching sensor in getReadingUnitSuffix for sensor: ', sensor);
      break;
  }

  return readingUnitSuffix;
}
