import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles, IconButton } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Link } from '@reach/router';

const useStyles = makeStyles((theme) => ({
  tableHeadCell: {
    borderStyle: "none",
    textTransform: "uppercase",
    color: "#575561",
    fontWeight: "bold",
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'online', label: 'Online', enableSort: false},
  { id: 'name', label: 'Site Name', enableSort: true },
  { id: 'sensor', label: 'Sensor', enableSort: true },
  { id: 'latestValue', label: 'Last Reading', enableSort: true },
  { id: 'timestamp', label: 'Time Received', enableSort: true },
  { id: 'download', label: 'Download', enableSort: false },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableHeadCell}
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.enableSort ? 
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
              : null
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ResourcesTable(props) {
  const classes = useStyles();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer component={Paper}>
      <Table style={{minWidth: 650}} aria-label="simple table">
        <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(props.resources, getComparator(order, orderBy))
            .map((resource, index) => {
              return (
                <TableRow
                  key={index}
                  hover
                >
                  <TableCell component="th" scope="row">
                    {resource.online ? 
                      <FiberManualRecordIcon style={{ color: 'green' }}/>
                    :
                      <FiberManualRecordIcon style={{ color: 'grey' }}/>
                    }
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Link
                      to='/resource'
                      state={{ siteName: resource.name, loggerSerialNumber: resource.loggerSerialNumber, sensorModel: resource.sensorModel, batteryLevel: resource.batteryLevel }}
                      style={{ textDecoration: 'none', color: 'inherit'}}
                    >
                      {resource.name}
                    </Link>
                  </TableCell>
                  <TableCell>{resource.sensor}</TableCell>
                  <TableCell>{resource.latestValue}</TableCell>
                  <TableCell>{resource.timestamp}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label='download'
                      onClick={() => {
                        props.setDownloadSensorID(resource.sensorID);
                        props.setDownloadSiteName(resource.name);
                        props.setOpenDownloadModal(true);
                      }}
                    >
                      <CloudDownloadIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
