import { createTheme  } from '@material-ui/core/styles';

const theme = createTheme ({
  // palette: {
  //   text:{
  //     primary: '#133368',
  //     secondary: '#1963F3',
  //   },
  //   primary: {
  //     main: '#2E97E5',
  //   },
  //   secondary: {
  //     main: '#1963F3',
  //   },
  //   online: {
  //     main: '#00FF00',
  //   },
  //   offline: {
  //     main: '#808080',
  //   },
  //   background: {
  //     paper: '#2E97E5',
  //     default: '#A5D5F2',
  //   },
  //   divider: "#1963F3",
  // },
});

export default theme;