import { API, graphqlOperation } from 'aws-amplify';
import { createCloudAlertRule, createNote, deleteCloudAlertRule, updateNote } from '../../graphql/mutations';
import { cloudAlertStateEventByCloudAlertRule, listCloudAlertRules, notificationStateEventByNotificationRule } from '../../graphql/custom-queries';

export async function apiCallCreateNote(noteInput) {
  const mutationResult = await API.graphql(graphqlOperation(createNote, {input: noteInput}));

  return mutationResult;
}

export async function apiCallArchiveNote(noteInput) {
  const mutationResult = await API.graphql(graphqlOperation(updateNote, {input: noteInput}));

  return mutationResult;
}


export async function apiCallCreateCloudAlertRule(cloudAlertRuleInput) {
  const mutationResult = await API.graphql(graphqlOperation(createCloudAlertRule, {input: cloudAlertRuleInput}));

  return mutationResult;
}

export async function apiCallDeleteCloudAlertRule(cloudAlertRuleInput) {
  const mutationResult = await API.graphql(graphqlOperation(deleteCloudAlertRule, {input: cloudAlertRuleInput}));

  return mutationResult;
}

export async function getCloudAlertRules() {
  let cloudAlertRules = [];
  let queryVariables = {};
  let nextToken = null;

  do {
    queryVariables.nextToken = nextToken;

    const queryResult = await API.graphql(
      {
        query: listCloudAlertRules,
        variables: queryVariables,
      }
    )
    .catch(e => console.log('error in getCloudAlertRules', e));

    cloudAlertRules = cloudAlertRules.concat(queryResult.data['listCloudAlertRules'].items);
    nextToken = queryResult.data['listCloudAlertRules'].nextToken;
  } while (nextToken != null);

  return cloudAlertRules;
}

export async function getLatestCloudAlertStateEvent(cloudAlertRuleID) {
  let latestCloudAlertStateEvents = null;

  const queryResult = await API.graphql(
    {
      query: cloudAlertStateEventByCloudAlertRule,
      variables: {
        cloudAlertRuleID: cloudAlertRuleID,
        sortDirection: 'DESC',
        limit: '1',
      }
    }
  )
  .catch(e => console.log('error in getLatestCloudAlertStateEvents', e));

  if (queryResult.data['cloudAlertStateEventByCloudAlertRule'].items.length > 0) {
    latestCloudAlertStateEvents = queryResult.data['cloudAlertStateEventByCloudAlertRule'].items[0];
  }

  return latestCloudAlertStateEvents;
}
