import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { AuthProvider } from '../shared/contexts/authenticationContext';

import Router from './router';

// import { AuthProvider } from '../shared/contexts/authenticationContext';
import { StateProvider } from '../shared/contexts/globalDataContext';

// Amplify.Logger.LOG_LEVEL = 'DEBUG';
// const logger = new Logger('app.js');

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [updateCurrentUserDone, setUpdateCurrentUserDone] = useState(false);

  let updateCurrentUser = async (user) => {
    if (user) {
      setCurrentUser(user);
      return;
    }

    await Auth.currentAuthenticatedUser()
    .then(user => {
      setCurrentUser(user);
    })
    .catch(err => {
      setCurrentUser(null);
    });
  };

  // On load try to fetch current user
  useEffect(()=>{

    updateCurrentUser()
    .then(() => setUpdateCurrentUserDone(true))
    .catch(error => {
      console.log("error fetching user...",error);
    });

  }, []);

  return (
    <AuthProvider value={{
      user: currentUser,
      updateCurrentUser: updateCurrentUser
    }}>
      <StateProvider>
        <Router/>
      </StateProvider>
    </AuthProvider>
  );
}

export default App;