import React from 'react';
import GoogleMapReact from 'google-map-react';
import GoogleMapStyles from '../../../assets/styles/googleMapStyles';
import { navigate } from '@reach/router';

import RoomIcon from '@material-ui/icons/Room';

export default function ResourcesMap(props) {
  const createMapOptions = (map) => {
    return {
      styles: GoogleMapStyles,
      mapTypeId: map.MapTypeId.SATELLITE,
    }
  };

  const defaultProps = {
    center: {
      lat: 49.24,
      lng: -123.1
    },
    zoom: 10
  };

  return (
    <div style={{ height: '96%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyByy_jBT-PXEauQvk9IQLfOVle8pfDAaog' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={createMapOptions}
      >
        {props.resources.map(resource => {
          return (
            <RoomIcon 
              style={{color: `${resource.online ? 'green' : 'grey'}`, position: 'absolute', transform: 'translate(-50%, -100%)'}}
              lat={resource.latitude}
              lng={resource.longitude}
              // onClick={() => navigate('/resource', { state:{ siteName: resource.name, loggerSerialNumber: resource.loggerSerialNumber, sensorModel: resource.sensorModel, batteryLevel: resource.batteryLevel } })}
              // onClick={() => navigate(`/resource/${resource.loggerID}`)}
            />
        );
        })}
      </GoogleMapReact>
    </div>
  );
}