import React, { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Typography, Button, Paper, InputLabel, Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useScrollTrigger, Chip, CircularProgress, Select, MenuItem, Tooltip, withStyles, Switch } from '@material-ui/core';
import { navigate, useLocation } from "@reach/router";
import { Web, FiberManualRecord, SystemUpdateAlt, ArrowBackIos, Info, SignalCellularNullRounded } from '@material-ui/icons';
import DayJS from '@date-io/dayjs';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';

import Navigator from '../../../shared/components/navigator.js';
import { globalDataStore } from '../../../shared/contexts/globalDataContext.js';
import { asyncForEach, getArrayAvgValue, getArrayMaxValue, getArrayMinValue } from '../../../shared/utils/arrays.js';
import { findResource, findResourcePropertyValue, getAlertCategory } from '../../../shared/utils/alert-utils.js';
import { apiCallCreateSensorEventDownloadRequest } from '../../../shared/api-calls/download-request-api-calls.js';
import { getSensorEventSeries } from '../../../shared/api-calls/sensor-api-calls.js';
import { getLiters } from '../../../shared/utils/get-liters.js';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ResourceChart from './resource-chart.js';
import getTvocFromVocIndex from '../../../shared/utils/get-tvoc.js';
import { Skeleton } from '@material-ui/lab';
import { getFloodEvents, updateFloodEvent } from '../../../shared/api-calls/flood-event-api-calls.js';
import { red } from '@material-ui/core/colors';

const SENSOR_REPORT_TABLE_DATA_GENERAL = [
  {
    category: 'Min Value',
    stateName: 'minValue',
  },
  {
    category: 'Avg Value',
    stateName: 'avgValue',
  },
  {
    category: 'Max Value',
    stateName: 'maxValue',
  },
];

const SENSOR_REPORT_TABLE_DATA_GI = [
  {
    category: 'Flood Events',
    stateName: 'numFloodEvents',
  },
  {
    category: 'Avg Flood Duration [hr]',
    stateName: 'avgFloodDurationHours',
  },
  {
    category: 'Longest Flood Duration [hr]',
    stateName: 'longestFloodDurationHours',
  },
  {
    category: 'Shortest Flood Duration [hr]',
    stateName: 'shortestFloodDurationHours',
  },
  {
    category: 'Avg Flood Height [m]',
    stateName: 'avgFloodHeightM',
  },
  {
    category: 'Avg Drawdown Rate [mm/hr]',
    stateName: 'avgDrawDownRateMmPerHour',
  },
  {
    category: 'Avg Drawdown Duration [hr]',
    stateName: 'avgDrawDownDurationHours',
  },
];

const ExcludeActivitySwitch = withStyles({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: red[500],
    },
    '&$checked + $track': {
      backgroundColor: red[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function ResourceAnalysis(props) {
  const [ resource, setResource ] = useState(null);
  const [ sensorID, setSensorID ] = useState(null);

  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [chartData, setChartData] = useState([]);
  const [selectedChartPeriod, setSelectedChartPeriod] = useState(0);

  const [ activityLogLoading, setActivityLogLoading ] = useState(true);
  const [ floodEvents, setFloodEvents ] = useState([]);
  const [ floodEventsForSensorReport, setFloodEventsForSensorReport ] = useState([]);

  const now = new Date();
  const previousMonthStartDate = new Date(now.getFullYear(), now.getMonth() - 1); // start of previous month
  const previousMonthEndDate = new Date(previousMonthStartDate.getFullYear(), previousMonthStartDate.getMonth() + 1, 1, 0, 0, 0, -1); // end of previous month
  const [ activityLogStartDate, setActivityLogStartDate ] = useState(previousMonthStartDate);
  const [ activityLogEndDate, setActivityLogEndDate ] = useState(previousMonthEndDate);

  const [ sensorReportMetricsGI, setSensorReportMetricsGI ] = useState(null);
  const [ sensorReportMetricsGeneral, setSensorReportMetricsGeneral ] = useState(null);
  const [ isSiteTypeGI, setIsSiteTypeGI ] = useState(false);

  const [ excludeActivitySwitchLoading, setExcludeActivitySwitchLoading ] = useState(false);

  const { state, dispatch } = useContext(globalDataStore);
  
  useEffect(() => {
    if (state.resourceDetails != null) {
      const resource = findResource(props.loggerID, state.resourceDetails, props.location.state.name);
      setResource(resource);
      setSensorID(resource.sensorID);

      if (resource != null) {
        if (resource.siteType === 'GI') {
          setIsSiteTypeGI(true);

          getFloodEvents(resource.sensorID, activityLogStartDate.toISOString(), activityLogEndDate.toISOString())
          .then(floodEventItems => {
            setFloodEvents(floodEventItems);
            setActivityLogLoading(false);

            const filteredFloodEventItems = floodEventItems.filter(floodEventItem => floodEventItem.excludeFromSensorReport === 'false');
            setFloodEventsForSensorReport(filteredFloodEventItems);

            const floodEventsDurationHours = filteredFloodEventItems.map(floodEventItem => ( (new Date(floodEventItem.endTimestamp).getTime() - new Date(floodEventItem.startTimestamp).getTime()) / 3600000) );
            const floodEventsHeightM = filteredFloodEventItems.map(floodEventItem => floodEventItem.peakWaterLevelM);
            setSensorReportMetricsGI (
              {
                numFloodEvents: filteredFloodEventItems.length,
                avgFloodDurationHours: getArrayAvgValue(floodEventsDurationHours),
                longestFloodDurationHours: getArrayMaxValue(floodEventsDurationHours),
                shortestFloodDurationHours: getArrayMinValue(floodEventsDurationHours),
                avgFloodHeightM: getArrayAvgValue(floodEventsHeightM),
                avgDrawDownRateMmPerHour: getArrayAvgValue(filteredFloodEventItems.map(floodEventItem => floodEventItem.drawdownRateMmPerHour * -1)).toFixed(3),
                avgDrawDownDurationHours: getArrayAvgValue(filteredFloodEventItems.map(floodEventItem => floodEventItem.drawdownTimeSeconds / 3600)),
              }
            )
          });
        }
        else {
          setActivityLogLoading(false);
        }
      }
    }
  }, []);

  function saveFile() {
    const startDateTime = startDate;
    startDateTime.setHours(0, 0, 0, 0);
    const endDateTime = endDate;
    endDateTime.setHours(23, 59, 59, 999);

    let sensorEventsToDownload = chartData.map(chartDataItem => {
      const sensorEventItemDate = chartDataItem.x;
      const sensorEventItemReading = chartDataItem.y;
      return `${sensorEventItemDate.toLocaleDateString()} ${sensorEventItemDate.toLocaleTimeString()},${sensorEventItemReading}\r\n`;
    });

    sensorEventsToDownload.unshift(`Timestamp, ${resource.sensor} [${resource.readingUnitSuffix}]\r\n`);
      
    const blob = new Blob(sensorEventsToDownload, {type: "text/plain;charset=utf-8"});
    const dateString = `${startDate.toDateString()} to ${endDate.toDateString()}`;
    const fileName = `${resource.name} - ${dateString}.csv`;
    FileSaver.saveAs(blob, fileName);

    Auth.currentAuthenticatedUser()
    .then(user => {
      const downloadRequest = {
        sensorID: sensorID,
        email: user.attributes.email,
        startTime: startDateTime.toISOString(),
        endTime: endDateTime.toISOString(),
        timeZone: 'America/Vancouver',
        siteName: resource.name,
      };
  
      apiCallCreateSensorEventDownloadRequest(downloadRequest)
      .catch(e => {
        console.log("error in createFileDownloadRequest", e);
      })
    })
  }

  useEffect(() => {
    if (sensorID != null) {
      setLoading(true);
      const startDateTime = startDate;
      startDateTime.setHours(0, 0, 0, 0);
      const endDateTime = endDate;
      endDateTime.setHours(23, 59, 59, 999);

      getSensorEventSeries(sensorID, startDateTime.toISOString(), endDateTime.toISOString())
      .then((sensorEventItems) => {
        // extract air quality data
        if (resource.sensor === 'Air Quality - PM1.0') {
          sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[0]);
        }
        else if (resource.sensor === 'Air Quality - PM2.5') {
          sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[1]);
        }
        else if (resource.sensor === 'Air Quality - PM4.0') {
          sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[2]);
        }
        else if (resource.sensor === 'Air Quality - PM10.0') {
          sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[3]);
        }
        else if (resource.sensor === 'Air Quality - Humidity') {
          sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[4]);
        }
        else if (resource.sensor === 'Air Quality - VOC') {
          sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = (getTvocFromVocIndex(sensorEventItem.reading.split(';')[5])).toFixed(3));
        }
        else if (resource.sensor === 'IMU - Roll') {
          sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[0]);
        }
        else if (resource.sensor === 'IMU - Pitch') {
          sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[1]);
        }
        else if (resource.sensor === 'IMU - Yaw') {
          sensorEventItems.forEach(sensorEventItem => sensorEventItem.reading = sensorEventItem.reading.split(';')[2]);
        }

        // remove items that are zeros (invalid pressure sensor data)
        sensorEventItems = sensorEventItems.filter (sensorEventItem => {
          return parseFloat(sensorEventItem.reading) !== 0;
        });

        sensorEventItems = sensorEventItems.map (sensorEventItem => {
          const timeStampDate = new Date(sensorEventItem.eventTimeStamp);
          const timeStampMinuteMod = timeStampDate.getMinutes() % 5;
          const timeStampSeconds = timeStampDate.getSeconds();
          if (timeStampMinuteMod > 2 || (timeStampMinuteMod === 2 && timeStampSeconds >= 30)) {
            // round up
            timeStampDate.setMinutes(timeStampDate.getMinutes() - timeStampMinuteMod + 5);
            timeStampDate.setSeconds(0);
          } else {
            // round down
            timeStampDate.setMinutes(timeStampDate.getMinutes() - timeStampMinuteMod);
            timeStampDate.setSeconds(0);
          }
          
          if (sensorID === '8') {
            return `${timeStampDate.toISOString()},${getLiters(sensorEventItem.eventTimeStamp, sensorEventItem.reading).toFixed(3)}\r\n`;
          }
          else if (resource.sensor.startsWith('Air Quality')) {
            return `${timeStampDate.toISOString()},${sensorEventItem.reading}\r\n`;
          }
          else {
            return `${timeStampDate.toISOString()},${(sensorEventItem.reading * 0.704944599).toFixed(3)}\r\n`;
          }
        });

        let index = 0;
        while (index < (sensorEventItems.length - 1)) {
          const currentSensorEventDate = new Date(sensorEventItems[index].split(',')[0]);
          const nextSensorEventDate = new Date(sensorEventItems[index + 1].split(',')[0]);

          const currentSensorEventReading = Number(sensorEventItems[index].split(',')[1]);
          const nextSensorEventReading = Number(sensorEventItems[index + 1].split(',')[1]);

          if ( (nextSensorEventDate.getTime() - currentSensorEventDate.getTime()) > 300000 ) {
            let interpolatedDate = currentSensorEventDate;
            interpolatedDate.setMinutes(currentSensorEventDate.getMinutes() + 5);
            const interpolatedReading = ((currentSensorEventReading + nextSensorEventReading) / 2).toFixed(3);
            const interpolatedSensorEvent = `${interpolatedDate.toISOString()},${interpolatedReading}\r\n`;
            
            sensorEventItems.splice(index + 1, 0, interpolatedSensorEvent);
          }

          if ( nextSensorEventDate.getTime() === currentSensorEventDate.getTime() ) {
            sensorEventItems.splice(index, 1);
            index--;
          }

          index++;
        }

        // keeping the timestamps in ISO format until now so that all time comparisons above are valid.
        sensorEventItems = sensorEventItems.map (sensorEventItem => {
          const sensorEventItemDate = new Date(sensorEventItem.split(',')[0]);
          const sensorEventItemReading = sensorEventItem.split(',')[1];
          return { x: sensorEventItemDate, y: parseFloat(sensorEventItemReading) };
        });

        setChartData(sensorEventItems);

        const sensorEventItemReadings = sensorEventItems.map(sensorEventItem => sensorEventItem.y);
        setSensorReportMetricsGeneral (
          {
            minValue: getArrayMinValue(sensorEventItemReadings).toFixed(3),
            maxValue: getArrayMaxValue(sensorEventItemReadings).toFixed(3),
            avgValue: getArrayAvgValue(sensorEventItemReadings).toFixed(3),
          }
        );

        setLoading(false);

        if (resource != null) {
          if (resource.siteType === 'GI') {
            getFloodEvents(resource.sensorID, activityLogStartDate.toISOString(), activityLogEndDate.toISOString())
            .then(floodEventItems => {
              setFloodEvents(floodEventItems);

              const filteredFloodEventItems = floodEventItems.filter(floodEventItem => floodEventItem.excludeFromSensorReport === 'false');
              setFloodEventsForSensorReport(filteredFloodEventItems);

              setActivityLogLoading(false);

              const floodEventsDurationHours = filteredFloodEventItems.map(floodEventItem => ( (new Date(floodEventItem.endTimestamp).getTime() - new Date(floodEventItem.startTimestamp).getTime()) / 3600000) );
              const floodEventsHeightM = filteredFloodEventItems.map(floodEventItem => floodEventItem.peakWaterLevelM);
              setSensorReportMetricsGI (
                {
                  numFloodEvents: filteredFloodEventItems.length,
                  avgFloodDurationHours: getArrayAvgValue(floodEventsDurationHours).toFixed(2),
                  longestFloodDurationHours: getArrayMaxValue(floodEventsDurationHours).toFixed(2),
                  shortestFloodDurationHours: getArrayMinValue(floodEventsDurationHours).toFixed(2),
                  avgFloodHeightM: getArrayAvgValue(floodEventsHeightM).toFixed(3),
                  avgDrawDownRateMmPerHour: getArrayAvgValue(filteredFloodEventItems.map(floodEventItem => floodEventItem.drawdownRateMmPerHour * -1)).toFixed(3),
                  avgDrawDownDurationHours: getArrayAvgValue(filteredFloodEventItems.map(floodEventItem => floodEventItem.drawdownTimeSeconds / 3600)).toFixed(2),
                }
              )
            });
          }
          else {
            setActivityLogLoading(false);
          }
        }
      });
    }
  }, [startDate, endDate, sensorID]);

  function handleChartPeriodSelector(event) {
    setSelectedChartPeriod(event.target.value);

    if (event.target.value === 0) { // today
      const now = new Date();

      setStartDate(now);
      setEndDate(now);

      const previousMonthStartDate = new Date(now.getFullYear(), now.getMonth() - 1); // start of previous month
      const previousMonthEndDate = new Date(previousMonthStartDate.getFullYear(), previousMonthStartDate.getMonth() + 1, 1, 0, 0, 0, -1); // end of previous month
      setActivityLogStartDate(previousMonthStartDate);
      setActivityLogEndDate(previousMonthEndDate);
    }
    else if (event.target.value === 1) {  // past 3 days
      const now = new Date();

      let newStartDate = new Date();
      newStartDate.setDate(newStartDate.getDate() - 2);
      setStartDate(newStartDate);
      setEndDate(new Date());

      const newStartDateMonthDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth()); // start of newStartDate's month
      const previousMonthStartDate = new Date(now.getFullYear(), now.getMonth() - 1); // start of previous month
      const previousMonthEndDate = new Date(previousMonthStartDate.getFullYear(), previousMonthStartDate.getMonth() + 1, 1, 0, 0, 0, -1); // end of previous month
      setActivityLogStartDate(newStartDateMonthDate.getTime() < previousMonthStartDate.getTime() ? newStartDateMonthDate : previousMonthStartDate);
      setActivityLogEndDate(previousMonthEndDate);
    }
    else if (event.target.value === 2) {  // past 7 days
      const now = new Date();

      let newStartDate = new Date();
      newStartDate.setDate(newStartDate.getDate() - 6);
      setStartDate(newStartDate);
      setEndDate(new Date());

      const newStartDateMonthDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth()); // start of newStartDate's month
      const previousMonthStartDate = new Date(now.getFullYear(), now.getMonth() - 1); // start of previous month
      const previousMonthEndDate = new Date(previousMonthStartDate.getFullYear(), previousMonthStartDate.getMonth() + 1, 1, 0, 0, 0, -1); // end of previous month
      setActivityLogStartDate(newStartDateMonthDate.getTime() < previousMonthStartDate.getTime() ? newStartDateMonthDate : previousMonthStartDate);
      setActivityLogEndDate(previousMonthEndDate);
    }
  }

  const handleExcludeActivityChange = (event) => {
    setExcludeActivitySwitchLoading(true);

    updateFloodEvent(
      {
        id: event.target.name,
        excludeFromSensorReport: event.target.checked ? 'true' : 'false'
      }
    ).then(updateFloodEventResult => {
      let updatedFloodEvents = floodEvents.map(floodEvent => {
        if (floodEvent.id === updateFloodEventResult.data.updateWellFloodEvent.id) {
          floodEvent.excludeFromSensorReport = updateFloodEventResult.data.updateWellFloodEvent.excludeFromSensorReport;
        }

        return floodEvent;
      });
      setFloodEvents(updatedFloodEvents);

      const filteredFloodEventItems = updatedFloodEvents.filter(floodEventItem => floodEventItem.excludeFromSensorReport === 'false');
      setFloodEventsForSensorReport(filteredFloodEventItems);

      const floodEventsDurationHours = filteredFloodEventItems.map(floodEventItem => ( (new Date(floodEventItem.endTimestamp).getTime() - new Date(floodEventItem.startTimestamp).getTime()) / 3600000) );
      const floodEventsHeightM = filteredFloodEventItems.map(floodEventItem => floodEventItem.peakWaterLevelM);
      setSensorReportMetricsGI (
        {
          numFloodEvents: filteredFloodEventItems.length,
          avgFloodDurationHours: getArrayAvgValue(floodEventsDurationHours).toFixed(2),
          longestFloodDurationHours: getArrayMaxValue(floodEventsDurationHours).toFixed(2),
          shortestFloodDurationHours: getArrayMinValue(floodEventsDurationHours).toFixed(2),
          avgFloodHeightM: getArrayAvgValue(floodEventsHeightM).toFixed(3),
          avgDrawDownRateMmPerHour: getArrayAvgValue(filteredFloodEventItems.map(floodEventItem => floodEventItem.drawdownRateMmPerHour * -1)).toFixed(3),
          avgDrawDownDurationHours: getArrayAvgValue(filteredFloodEventItems.map(floodEventItem => floodEventItem.drawdownTimeSeconds / 3600)).toFixed(2),
        }
      );

      setExcludeActivitySwitchLoading(false);
    });
  };

  return (
    <div style={{flexGrow: 1, marginLeft: 240, backgroundColor: '#F7F7F7'}}>
      <Navigator activeDrawerItem='Sites' />
      <Grid style={{margin: 24, width: '95%'}}>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50}} alignItems='center'>
          <Grid item style={{marginLeft: 10, marginRight: 10}}>
            <Web fontSize='large' color='primary' />
          </Grid>
          <Grid item >
            <Typography variant='body1' style={{fontWeight: 'bold'}}>{`Site Analysis${resource ? `: ${resource.name}` : null}`}</Typography>
          </Grid>
        </Grid>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50, marginTop: 14}} alignItems='center'>
          <Grid style={{marginLeft: 10}} container item justifyContent='space-between' alignItems='center'>
            <Button aria-controls='back' color='primary' variant='outlined' onClick={() => navigate(`/resource/${props.loggerID}`)} style={{ borderRadius: 4, width: 180,/* height: '60%' *//* , marginRight: '2%' */ }}>
              <ArrowBackIos style={{ marginRight: '10%' }} />
              <Typography>Site Details</Typography>
            </Button>
            <Button aria-controls='analyze' color='primary' variant='contained' onClick={() => saveFile()} style={{ borderRadius: 4, height: '60%', marginRight: '2%' }}>
              <SystemUpdateAlt style={{ marginRight: '10%' }} />
              <Typography style={{ color: 'white' }}>Export</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container style={{marginTop: 24}} >
          <Grid item xs={4}>
            <Paper style={{backgroundColor: 'white', borderRadius: 8, height: 41, display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
              <Typography variant='body2' style={{marginLeft: 10, fontWeight: 'bold'}}>Sensor Report</Typography>
            </Paper>
            <TableContainer component={Paper} style={{ marginTop: 10 }}>
              <Table size='small' stickyHeader aria-label='Sensor Report'>
                <TableHead>
                  <TableRow selected>
                    <TableCell>Category</TableCell>
                    <TableCell align='left'>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isSiteTypeGI ? SENSOR_REPORT_TABLE_DATA_GI.map((sensorReportTableItem, index) => (
                    <TableRow key={index}>
                      <TableCell component='th' scope='row'>
                        {sensorReportTableItem.category}
                      </TableCell>
                      <TableCell align='left'>{sensorReportMetricsGI != null ? sensorReportMetricsGI[sensorReportTableItem.stateName] : 'n/a'}</TableCell>
                    </TableRow>
                  ))
                  :
                  SENSOR_REPORT_TABLE_DATA_GENERAL.map((sensorReportTableItem, index) => (
                    <TableRow key={index}>
                      <TableCell component='th' scope='row'>
                        {sensorReportTableItem.category}
                      </TableCell>
                      <TableCell align='left'>{sensorReportMetricsGeneral != null ? sensorReportMetricsGeneral[sensorReportTableItem.stateName] : 'n/a'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={8}>
            <Paper style={{backgroundColor: 'white', minHeight: 318, marginLeft: 12, justifyContent: 'center', display: 'flex', flexDirection: 'row'}}>
              <Select 
                variant='filled'
                value={selectedChartPeriod}
                onChange={handleChartPeriodSelector}
                disableUnderline
                margin='dense'
                style={{ marginTop: 12, marginLeft: 12, maxHeight: 50 }}
              >
                <MenuItem value={0} >Today</MenuItem>
                <MenuItem value={1} >Last 3 Days</MenuItem>
                <MenuItem value={2} >Last 7 Days</MenuItem>
                <MenuItem value={3} disabled >Custom</MenuItem>
              </Select>
              <Grid container direction='row' wrap='nowrap' justifyContent='center' >
                <Grid item >
                  
                </Grid>
                <Grid item style={{ /* maxHeight: 200 */ }} >
                  <Grid container direction='row' >
                    <MuiPickersUtilsProvider utils={DayJS} >
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk 
                        // fullWidth
                        // className="datePicker"  
                        // variant="inline"
                        disableFuture
                        minDate = {dayjs('2022-02-15T08:00:00.000Z')}
                        InputProps={{disableUnderline: true}}
                        format = "MMM D , YYYY"
                        // keyboardIcon={
                        //   <Icon >
                        //     <ExpandMoreIcon style={{color: "#FFFFFF"}} height="20px" />
                        //   </Icon>
                        // }
                        inputstyle={{ textAlign: 'center'}}
                        value={startDate} 
                        // required
                        style={{maxWidth: 180, marginLeft: 20, marginRight: 20}}
                        margin="normal" 
                        size="medium"
                        onChange={(date) => {
                          setStartDate(date.toDate());
                          handleChartPeriodSelector({target: {value: 3}});

                          const now = new Date();
                          const newStartDateMonthDate = new Date((date.toDate()).getFullYear(), (date.toDate()).getMonth()); // start of newStartDate's month
                          const previousMonthStartDate = new Date(now.getFullYear(), now.getMonth() - 1); // start of previous month
                          setActivityLogStartDate(newStartDateMonthDate.getTime() < previousMonthStartDate.getTime() ? newStartDateMonthDate : previousMonthStartDate);
                        }}
                        label={'Start Date'}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DayJS} >
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk 
                        // fullWidth
                        // className="datePicker"  
                        // variant="inline"
                        disableFuture
                        minDate = {dayjs(startDate)}
                        InputProps={{disableUnderline: true}}
                        format = "MMM D , YYYY"
                        // keyboardIcon={
                        //   <Icon >
                        //     <ExpandMoreIcon style={{color: "#FFFFFF"}} height="20px" />
                        //   </Icon>
                        // }
                        inputstyle={{ textAlign: 'center'}}
                        value={endDate} 
                        // required
                        style={{maxWidth: 180, marginLeft: 20, marginRight: 20}}
                        margin="normal" 
                        size="medium"
                        onChange={(date) => {
                          setEndDate(date.toDate());
                          handleChartPeriodSelector({target: {value: 3}});

                          const now = new Date();
                          const previousMonthStartDate = new Date(now.getFullYear(), now.getMonth() - 1); // start of previous month
                          const previousMonthEndDate = new Date(previousMonthStartDate.getFullYear(), previousMonthStartDate.getMonth() + 1, 1, 0, 0, 0, -1); // end of previous month
                          const newEndDateMonthDate = new Date((date.toDate()).getFullYear(), (date.toDate()).getMonth() + 1, 1, 0, 0, 0, -1); // end of newEndDate's month
                          setActivityLogEndDate(newEndDateMonthDate.getTime() < previousMonthEndDate.getTime() ? newEndDateMonthDate : previousMonthEndDate);
                        }}
                        label={'End Date'}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid style={{ height: 240 }}>
                    {
                      loading ? <CircularProgress style={{margin: 'auto', marginTop: '30%'}}/> :
                      <ResourceChart
                        chartData={chartData}
                        unitLabel={resource.readingUnitSuffix}
                        domain={[resource.chartMinValue, resource.chartMaxValue]}
                        points={ chartData.length > 0 ?
                          floodEvents.filter( floodEvent =>
                            new Date(floodEvent.startTimestamp).getTime() >= chartData[0].x.getTime()
                            && new Date(floodEvent.endTimestamp).getTime() <= chartData[chartData.length - 1].x.getTime()
                          ).map( floodEvent => 
                            {
                              let peakTimestampMs = new Date(floodEvent.endTimestamp).getTime() - floodEvent.drawdownTimeSeconds * 1000;
                              return {
                                x: new Date(peakTimestampMs),
                                y: parseFloat(floodEvent.peakWaterLevelM),
                              }
                            }
                          ) : []
                        }
                        excludedEvents={ chartData.length > 0 ?
                          floodEvents.filter( floodEvent =>
                            floodEvent.excludeFromSensorReport === 'true'
                            && new Date(floodEvent.startTimestamp).getTime() >= chartData[0].x.getTime()
                            && new Date(floodEvent.endTimestamp).getTime() <= chartData[chartData.length - 1].x.getTime()
                          ) : []
                        }
                        includedEvents={ chartData.length > 0 ?
                          floodEvents.filter( floodEvent =>
                            floodEvent.excludeFromSensorReport === 'false'
                            && new Date(floodEvent.startTimestamp).getTime() >= chartData[0].x.getTime()
                            && new Date(floodEvent.endTimestamp).getTime() <= chartData[chartData.length - 1].x.getTime()
                          ) : []
                        }
                      />
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50, marginTop: 14}} alignItems='center'>
          <Grid style={{marginLeft: 10}} item >
            <Typography display='inline' variant='body2' style={{fontWeight: 'bold'}}>Activity Log | </Typography>
            <Typography display='inline' variant='body2' style={{fontWeight: 'bold'}}>{` ${activityLogStartDate.toLocaleString('default', { month: 'long' })}`}</Typography>
            <Typography display='inline' variant='body2' style={{fontWeight: 'bold'}}>{activityLogStartDate.getMonth() === activityLogEndDate.getMonth() ? null : ` - ${activityLogEndDate.toLocaleString('default', { month: 'long' })}`}</Typography>
            <Tooltip title={`For increased accuracy, at least 1 month's data is needed to generate the Activity Log. Therefore, the data for the current month will be available next month.`}>
              <Info fontSize='small' />
            </Tooltip>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table size='small' stickyHeader aria-label='Activity Log'>
            <TableHead>
              <TableRow selected>
                <TableCell>Start Date</TableCell>
                <TableCell align='left'>Start Time</TableCell>
                <TableCell align='left'>Flood Duration [hr]</TableCell>
                <TableCell align='left'>Drawdown Rate [mm/hr]</TableCell>
                <TableCell align='left'>Drawdown Time [hr]</TableCell>
                <TableCell align='left'>Peak Water Level [m]</TableCell>
                <TableCell>Exclude in Sensor Report</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {floodEvents ? floodEvents.map((floodEvent) => (
                <TableRow key={floodEvent.id}>
                  <TableCell component='th' scope='row'>
                    {activityLogLoading ? <Skeleton /> : new Date(floodEvent.startTimestamp).toLocaleDateString() }
                  </TableCell>
                  <TableCell align='left'>
                    {activityLogLoading ? <Skeleton /> : new Date(floodEvent.startTimestamp).toLocaleTimeString() }
                  </TableCell>
                  <TableCell align='left'>
                    {activityLogLoading ? <Skeleton /> : ( (new Date(floodEvent.endTimestamp).getTime() - new Date(floodEvent.startTimestamp).getTime()) / 3600000).toFixed(2) }
                  </TableCell>
                  <TableCell align='left'>
                    {activityLogLoading ? <Skeleton /> : floodEvent.peakWaterLevelM !== 'null' ? (floodEvent.drawdownRateMmPerHour * -1).toFixed(3) : 'NaN' }
                  </TableCell>
                  <TableCell align='left'>
                    {activityLogLoading ? <Skeleton /> : (floodEvent.drawdownTimeSeconds / 3600).toFixed(2) }
                  </TableCell>
                  <TableCell align='left'>
                    {activityLogLoading ? <Skeleton /> : parseFloat(floodEvent.peakWaterLevelM).toFixed(3) }
                  </TableCell>
                  <TableCell>
                    {activityLogLoading ? 
                      <Skeleton /> : 
                      <ExcludeActivitySwitch
                        name={floodEvent.id}
                        checked={floodEvent.excludeFromSensorReport === 'true'}
                        onChange={handleExcludeActivityChange}
                        disabled={excludeActivitySwitchLoading}
                      /> 
                    }
                  </TableCell>           
                </TableRow>
              )) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}