import React, { useReducer } from 'react';

const initialState = {};
const globalDataStore = React.createContext(initialState);
const { Provider } = globalDataStore;

const StateProvider = ( { children } ) => {

  const reducer = (state, action) => {
    switch(action.type) {
      case 'resourceDetails':
        return {...state, resourceDetails: action.payload};
      case 'alertRules':
        return {...state, alertRules: action.payload};
      case 'cleanup':
        return {
          resourceDetails: null,
          alertRules: null,
        };
      default:
        throw new Error();
    };
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (<Provider value={{ state, dispatch }}>{children}</Provider>);
};

export { globalDataStore, StateProvider }
