import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Paper, Typography, TextField, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Button, Menu, MenuItem, FormControlLabel, Switch, Dialog, DialogContent, Input, Select, Checkbox, ListItemText, Chip, Snackbar, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Notifications, ViewColumn, AddAlert, Label, Tune, LocationOn, Delete, Email, Sms, Cancel, Save, Timelapse } from '@material-ui/icons';


import Navigator from '../../shared/components/navigator';
import Footer from '../../shared/components/footer';
import { globalDataStore } from '../../shared/contexts/globalDataContext';
import { apiCallCreateCloudAlertRule, apiCallDeleteCloudAlertRule, getCloudAlertRules } from '../../shared/api-calls/notification-api-calls';
import { asyncForEach } from '../../shared/utils/arrays';
import { Skeleton } from '@material-ui/lab';
import { getAlertCategory, getMeasureCode, findResource, findResourcePropertyValue } from '../../shared/utils/alert-utils';
import { getVocIndexFromTvoc } from '../../shared/utils/get-tvoc';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

function getThreshold(quantity, category) {
  let threshold = quantity;

  if (category === 'Water Level') {
    threshold = quantity / 0.704944599;
  }
  else if (category === 'VOC') {
    threshold = getVocIndexFromTvoc(quantity);
  }

  return threshold;
}

function getSubSensorID(sensor) {
  let subSensorID = null;

  switch (sensor) {
    case 'Air Quality - PM1.0':
      subSensorID = '0';
      break;
    case 'Air Quality - PM2.5':
      subSensorID = '1';
      break;
    case 'Air Quality - PM4.0':
      subSensorID = '2';
      break;
    case 'Air Quality - PM10.0':
      subSensorID = '3';
      break;
    case 'Air Quality - Humidity':
      subSensorID = '4';
      break;
    case 'Air Quality - VOC':
      subSensorID = '5';
      break;
    case 'IMU - Roll':
      subSensorID = '0';
      break;
    case 'IMU - Pitch':
      subSensorID = '1';
      break;
    case 'IMU - Yaw':
      subSensorID = '2';
      break;

    default:
      console.error('No matching sensor in getSubSensorID for sensor: ', sensor);
      break;
  }

  return subSensorID;
}

export default function Alerts (props) {
  const [ loading, setLoading ] = useState(false);
  const [ refresh, setRefresh ] = useState(true);
  const [columnsMenuAnchorElement, setColumnsMenuAnchorElement] = useState(false);
  const [showColumnAlertLabel, setShowColumnAlertLabel] = useState(true);
  const [showColumnCategory, setShowColumnCategory] = useState(true);
  const [showColumnSites, setShowColumnSites] = useState(true);
  const [showColumnContacts, setShowColumnContacts] = useState(true);
  const [showColumnAlerts, setShowColumnAlerts] = useState(true);
  const [showColumnActions, setShowColumnActions] = useState(true);
  const [addAlertModalOpen, setAddAlertModalOpen] = useState(false);
  const [newAlertName, setNewAlertName] = useState(null);
  const [newAlertCategory, setNewAlertCategory] = useState(null);
  const [newAlertRelationship, setNewAlertRelationship] = useState(null);
  const [newAlertQuantity, setNewAlertQuantity] = useState(null);
  const [newAlertUnit, setNewAlertUnit] = useState(null);
  const [newAlertDurationMinutes, setNewAlertDurationMinutes] = useState(null);
  const [newAlertResourceDetails, setNewAlertResourceDetails] = useState([]);
  const [newAlertEmailList, setNewAlertEmailList] = useState(null);
  const [newAlertSmsList, setNewAlertSmsList] = useState(null);
  const [saveNewAlertLoading, setSaveNewAlertLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [alertRules, setAlertRules] = useState([]);

  const {state, dispatch} = useContext(globalDataStore);

  useEffect(() => {
    if (refresh) {
      setLoading(true);

      getCloudAlertRules()
      .then(cloudAlertRules => {
        setAlertRules(cloudAlertRules);
        dispatch({ type: 'alertRules', payload: cloudAlertRules });
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [refresh]);

  const handleSelectedSitesChange = (event) => {
    setNewAlertResourceDetails(event.target.value);
  };

  function handleColumnsButtonClick(event) {
    setColumnsMenuAnchorElement(event.target);
  }

  function handleColumnsMenuClose() {
    setColumnsMenuAnchorElement(false);
  }

  function handleNameChange(event) {
    setNewAlertName(event.target.value);
  }

  function handleCategoryChange(event) {
    setNewAlertCategory(event.target.value);

    if (event.target.value === 'Water Level') {
      setNewAlertUnit('m');
    }
    else if (event.target.value === 'PM1.0') {
      setNewAlertUnit('ug/m3');
    }
    else if (event.target.value === 'PM2.5') {
      setNewAlertUnit('ug/m3');
    }
    else if (event.target.value === 'PM4.0') {
      setNewAlertUnit('ug/m3');
    }
    else if (event.target.value === 'PM10.0') {
      setNewAlertUnit('ug/m3');
    }
    else if (event.target.value === 'Humidity') {
      setNewAlertUnit('%RH');
    }
    else if (event.target.value === 'VOC') {
      setNewAlertUnit('PPB');
    }
    else if (event.target.value === 'Roll') {
      setNewAlertUnit('deg');
    }
    else if (event.target.value === 'Pitch') {
      setNewAlertUnit('deg');
    }
    else if (event.target.value === 'Yaw') {
      setNewAlertUnit('deg');
    }
    else if (event.target.value === 'Ambient Light') {
      setNewAlertUnit('lux');
    }
    else if (event.target.value === 'Logger Battery Level') {
      setNewAlertUnit('%');
    }
  }

  function handleRelationshipChange(event) {
    setNewAlertRelationship(event.target.value);
  }

  function handleQuantityChange(event) {
    setNewAlertQuantity(event.target.value);
  }

  function handleDurationMinutesChange(event) {
    setNewAlertDurationMinutes(event.target.value);
  }

  function handleEmailListChange(event) {
    setNewAlertEmailList(event.target.value);
  }

  function handleSmsListChange(event) {
    setNewAlertSmsList(event.target.value);
  }

  function handleSaveNewAlert() {
    setErrorMessage(null);
    setSaveNewAlertLoading(true);

    if (newAlertDurationMinutes > 1440) {
      setErrorMessage('Duration cannot be more than 1 day (1440)');
    }
    else {
      Auth.currentAuthenticatedUser()
      .then(user => {
        let cloudAlertRulesToCreate = newAlertResourceDetails.map(newAlertResourceDetail => {
          if (newAlertResourceDetail != null) {
            if (newAlertCategory === 'Logger Battery Level') {
              return {
                userID: user.attributes.email,
                loggerID: newAlertResourceDetail.loggerID,
                name: newAlertName,
                siteName: newAlertResourceDetail.name,
                category: newAlertCategory,
                parameter: newAlertCategory === 'Logger Battery Level' ? 'batteryLevelPercent' : 'reading',
                condition: newAlertRelationship,
                threshold: getThreshold(newAlertQuantity, newAlertCategory),
                durationSeconds: newAlertDurationMinutes * 60,
                emailList: newAlertEmailList,
                smsList: newAlertSmsList,
                enabled: 'true',
              };
            }
            else {
              return {
                userID: user.attributes.email,
                loggerID: newAlertResourceDetail.loggerID,
                sensorID: newAlertResourceDetail.sensorID,
                subSensorID: getSubSensorID(newAlertResourceDetail.sensor),
                name: newAlertName,
                siteName: newAlertResourceDetail.name,
                category: newAlertCategory,
                parameter: newAlertCategory === 'Logger Battery Level' ? 'batteryLevelPercent' : 'reading',
                condition: newAlertRelationship,
                threshold: getThreshold(newAlertQuantity, newAlertCategory),
                durationSeconds: newAlertDurationMinutes * 60,
                emailList: newAlertEmailList,
                smsList: newAlertSmsList,
                enabled: 'true',
              };
            }
          }
          else {
            return null;
          }
        });
        
        asyncForEach(cloudAlertRulesToCreate, async cloudAlertRuleInput => {
          apiCallCreateCloudAlertRule(cloudAlertRuleInput)
          .catch(e => {
            setSaveNewAlertLoading(false);
            console.error("error in apiCallCreateCloudAlertRule", e);
            setErrorMessage('Error while attempting to create Alert!');
            setAddAlertModalOpen(false);
          });
        })
        .then(() => {
          setSaveNewAlertLoading(false);
          setAddAlertModalOpen(false);
          setRefresh(true);
        });
      });
    }
  }

  function deleteCloudAlertRule(cloudRuleID) {
    apiCallDeleteCloudAlertRule({id: cloudRuleID})
    .catch(e => {
      console.error("error in apiCallDeleteCloudAlertRule", e);
      setErrorMessage('Error while attempting to delete Alert!');
    })
    .then(() => {
      setRefresh(true);
    });
  }

  return (
    <div style={{flexGrow: 1, marginLeft: 240, backgroundColor: '#F7F7F7'}}>
      <Navigator activeDrawerItem='Alerts' />
      <Grid style={{margin: 24, width: '95%'}}>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50}} alignItems='center'>
          <Grid item style={{marginLeft: 10, marginRight: 10}}>
            <Notifications fontSize='large' color='primary' />
          </Grid>
          <Grid item >
            <Typography variant='body1' style={{fontWeight: 'bold'}}>Alerts</Typography>
          </Grid>
        </Grid>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50, marginTop: 14}} alignItems='center'>
          <Grid style={{marginLeft: 10}} container justifyContent='space-between' alignItems='center' direction='row'>
            <Grid>
              <IconButton aria-controls='select-columns' aria-haspopup='true' onClick={handleColumnsButtonClick} style={{ borderRadius: 8 }}>
                <ViewColumn color='primary' />
                <Typography color='textPrimary' >Columns</Typography>
              </IconButton>
              <Menu
                id='simple-menu'
                anchorEl={columnsMenuAnchorElement}
                keepMounted
                open={Boolean(columnsMenuAnchorElement)}
                onClose={handleColumnsMenuClose}
              >
                <MenuItem>
                  <FormControlLabel
                    control={<Switch checked={showColumnAlertLabel} onChange={() => setShowColumnAlertLabel(!showColumnAlertLabel)} color='primary' />}
                    label='Alert Label'
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    control={<Switch checked={showColumnCategory} onChange={() => setShowColumnCategory(!showColumnCategory)} color='primary' />}
                    label='Category'
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    control={<Switch checked={showColumnSites} onChange={() => setShowColumnSites(!showColumnSites)} color='primary' />}
                    label='Sites'
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    control={<Switch checked={showColumnContacts} onChange={() => setShowColumnContacts(!showColumnContacts)} color='primary' />}
                    label='Contacts'
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    control={<Switch checked={showColumnAlerts} onChange={() => setShowColumnAlerts(!showColumnAlerts)} color='primary' />}
                    label='Alerts'
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    control={<Switch checked={showColumnActions} onChange={() => setShowColumnActions(!showColumnActions)} color='primary' />}
                    label='Actions'
                  />
                </MenuItem>
              </Menu>
              {/* <FormControlLabel control={<Switch color='primary' />} label='Enable All Alerts' /> */}
            </Grid>
            <Grid style={{ marginRight: '2%' }}>
              <Button
                aria-controls='add-alert'
                color='primary'
                variant='contained'
                onClick={() => setAddAlertModalOpen(true)}
                style={{ borderRadius: 4, width: '200px', height: '60%' }}
              >
                <AddAlert style={{ marginRight: '10%' }} />
                <Typography style={{ color: 'white' }}>Add Alert</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table size='small' stickyHeader aria-label='Sites Table'>
            <TableHead>
              <TableRow selected>
                {showColumnAlertLabel && <TableCell>Alert Label</TableCell>}
                {showColumnCategory && <TableCell align='left'>Category</TableCell>}
                {showColumnSites && <TableCell align='left'>Sites</TableCell>}
                {showColumnContacts && <TableCell align='left'>Contacts</TableCell>}
                {showColumnAlerts && <TableCell align='left'>Alerts</TableCell>}
                {showColumnActions && <TableCell align='left'>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                loading ? <Skeleton /> :
                alertRules.map((alertRule) => 
                  <TableRow key={alertRule.id}>
                    {showColumnAlertLabel && <TableCell component='th' scope='row'>{alertRule.name}</TableCell>}
                    {showColumnCategory && <TableCell align='left'>{alertRule.category}</TableCell>}
                    {showColumnSites && <TableCell align='left'>{alertRule.siteName}</TableCell>}
                    {showColumnContacts && <TableCell align='left'>{alertRule.emailList}</TableCell>}
                    {showColumnAlerts && <TableCell align='left'>{alertRule.enabled === 'true' ? 'On' : 'Off'}</TableCell>}
                    {showColumnActions && 
                      <TableCell align='left'>
                        <Button
                          aria-controls='delete-cloud-alert-rule'
                          color='primary'
                          size='small'
                          onClick={() => deleteCloudAlertRule(alertRule.id)}
                        >
                          <Delete />
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={addAlertModalOpen}
          onClose={() => setAddAlertModalOpen(false)}
          aria-labelledby='add-alert-modal'
          aria-describedby='modal-for-adding-new-alert'
          maxWidth='md'
          PaperProps={{ style: { backgroundColor: '#F7F7F7', width: '100%', minHeight: '100vh' } }}
        >
          <DialogContent style={{ backgroundColor: '#FFFFFF', margin: 24 }}>
            <Grid container spacing={1} alignItems='flex-end'>
              <Grid item>
                <Label />
              </Grid>
              <Grid item style={{ width: '90%' }} >
                <TextField
                  autoFocus
                  margin='dense'
                  id='name'
                  label='Alert Label'
                  type='text'
                  fullWidth
                  value={newAlertName}
                  onChange={handleNameChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent style={{ backgroundColor: '#FFFFFF', marginBottom: 24, marginRight: 24, marginLeft: 24 }}>
            <Grid container spacing={1} alignItems='center'>
              <Grid item>
                <Tune />
              </Grid>
              <Grid item >
                <Typography variant='caption'>
                  Alert Conditions
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems='center'>
              <Typography>
                Send Alerts when
              </Typography>
              <Grid item >
                <Select
                  variant='outlined'
                  value={newAlertCategory}
                  onChange={handleCategoryChange}
                  displayEmpty
                  margin='dense'
                  style={{ color: newAlertCategory == null ? '#0000008A' : 'inherit' }}
                >
                  <MenuItem value={null} style={{ color: '#0000008A' }}>category</MenuItem>
                  <MenuItem value={'Water Level'}>Water Level</MenuItem>
                  {/* <MenuItem value={'PM1.0'}>PM1.0</MenuItem> */}
                  <MenuItem value={'PM2.5'}>PM2.5</MenuItem>
                  {/* <MenuItem value={'PM4.0'}>PM4.0</MenuItem> */}
                  <MenuItem value={'PM10.0'}>PM10.0</MenuItem>
                  <MenuItem value={'Humidity'}>Humidity</MenuItem>
                  <MenuItem value={'VOC'}>VOC</MenuItem>
                  <MenuItem value={'Roll'}>Roll</MenuItem>
                  <MenuItem value={'Pitch'}>Pitch</MenuItem>
                  <MenuItem value={'Yaw'}>Yaw</MenuItem>
                  <MenuItem value={'Ambient Light'}>Ambient Light</MenuItem>
                  <MenuItem value={'Logger Battery Level'}>Logger Battery Level</MenuItem>
                </Select>
              </Grid>
              <Typography>
                is
              </Typography>
              <Grid item >
                <Select
                  variant='outlined'
                  value={newAlertRelationship}
                  onChange={handleRelationshipChange}
                  displayEmpty
                  margin='dense'
                  style={{ color: newAlertRelationship == null ? '#0000008A' : 'inherit' }}
                >
                  <MenuItem value={null} style={{ color: '#0000008A' }}>relationship</MenuItem>
                  <MenuItem value={'lt'}>Less than</MenuItem>
                  <MenuItem value={'gt'}>Greater than</MenuItem>
                  <MenuItem value={'le'}>Less than or equal to</MenuItem>
                  <MenuItem value={'ge'}>Greater than or equal to</MenuItem>
                  <MenuItem value={'ne'}>Not equal to</MenuItem>
                  <MenuItem value={'eq'}>Equal to</MenuItem>
                </Select>
              </Grid>
              <Grid item >
                <TextField
                  id='quantity'
                  label='quantity'
                  type='number'
                  variant='outlined'
                  value={newAlertQuantity}
                  onChange={handleQuantityChange}
                  margin='dense'
                  style={{ marginBottom: 8 }}
                />
              </Grid>
              <Typography>
                { newAlertUnit }
              </Typography>
            </Grid>
          </DialogContent>
          <DialogContent style={{ backgroundColor: '#FFFFFF', marginBottom: 24, marginRight: 24, marginLeft: 24 }}>
            <Grid container spacing={1} alignItems='center'>
              <Grid item>
                <Timelapse />
              </Grid>
              <Grid item >
                <Typography variant='caption'>
                  Duration
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems='center'>
              <Typography>
                Send Alerts after alert conditions have been satisfied for 
              </Typography>
              <Grid item >
                <TextField
                  id='durationMinutes'
                  label='duration'
                  type='number'
                  variant='outlined'
                  value={newAlertDurationMinutes}
                  onChange={handleDurationMinutesChange}
                  margin='dense'
                  style={{ marginBottom: 8 }}
                />
              </Grid>
              <Typography>
                minutes
              </Typography>
            </Grid>
          </DialogContent>
          <DialogContent style={{ backgroundColor: '#FFFFFF', marginBottom: 24, marginRight: 24, marginLeft: 24 }}>
            <Grid container spacing={1} alignItems='center'>
              <Grid item>
                <LocationOn />
              </Grid>
              <Grid item >
                <Typography variant='caption'>
                  Sites
                </Typography>
              </Grid>
              <Button
                aria-controls='clear-all-sites'
                color='primary'
                size='small'
                onClick={() => setNewAlertResourceDetails([])}
                style={{ marginLeft: 'auto' }}
              >
                <Delete />
                <Typography>Clear All</Typography>
              </Button>
            </Grid>
            <Select
              multiple
              value={newAlertResourceDetails}
              onChange={handleSelectedSitesChange}
              input={<Input />}
              renderValue={(selected) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                  {selected.map((value, index) => (
                    <Chip key={index} label={value.name} style={{ margin: 2, borderRadius: 4 }} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
              style={{ width: '100%' }}
              variant='outlined'
            >
              {state.resourceDetails.map((resourceDetail, index) => (
                <MenuItem key={index} value={resourceDetail}>
                  <Checkbox checked={newAlertResourceDetails.some(newAlertResourceDetail => newAlertResourceDetail.loggerID === resourceDetail.loggerID && newAlertResourceDetail.sensor === resourceDetail.sensor)} color='primary' />
                  <ListItemText primary={resourceDetail.name} />
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogContent style={{ backgroundColor: '#FFFFFF', marginBottom: 24, marginRight: 24, marginLeft: 24 }}>
            <Grid container spacing={1} alignItems='center'>
              <Grid item>
                <Email />
              </Grid>
              <Grid item >
                <Typography variant='caption'>
                  Email List
                </Typography>
              </Grid>
              <Button
                aria-controls='clear-all-emails'
                color='primary'
                size='small'
                onClick={() => setNewAlertEmailList('')}
                style={{ marginLeft: 'auto' }}
              >
                <Delete />
                <Typography>Clear All</Typography>
              </Button>
            </Grid>
            <TextField
              margin='dense'
              id='email-list'
              type='text'
              variant='outlined'
              value={newAlertEmailList}
              onChange={handleEmailListChange}
              fullWidth
              placeholder={'Multiple email addresses may be added with colon(;) separation'}
            />
          </DialogContent>
          <DialogContent style={{ backgroundColor: '#FFFFFF', marginBottom: 24, marginRight: 24, marginLeft: 24 }}>
            <Grid container spacing={1} alignItems='center'>
              <Grid item>
                <Sms />
              </Grid>
              <Grid item >
                <Typography variant='caption'>
                  SMS List
                </Typography>
              </Grid>
              <Button
                aria-controls='clear-all-sms'
                color='primary'
                size='small'
                onClick={() => setNewAlertSmsList('')}
                style={{ marginLeft: 'auto' }}
              >
                <Delete />
                <Typography>Clear All</Typography>
              </Button>
            </Grid>
            <TextField
              margin='dense'
              id='sms-list'
              type='text'
              variant='outlined'
              value={newAlertSmsList}
              onChange={handleSmsListChange}
              fullWidth
              placeholder={'Multiple phone numbers may be added with colon(;) separation'}
            />
          </DialogContent>
          <Grid style={{ marginLeft: 'auto' }}>
            <Button
              aria-controls='cancel-alert-dialog'
              variant='contained'
              onClick={() => setAddAlertModalOpen(false)}
              style={{ borderRadius: 4, marginBottom: 24, marginRight: 24 }}
            >
              <Cancel />
              <Typography>Cancel</Typography>
            </Button>
            <Button
              aria-controls='save-new-alert'
              color='primary'
              variant='contained'
              onClick={handleSaveNewAlert}
              style={{ borderRadius: 4, marginBottom: 24, marginRight: 24, width: 100 }}
            >
              {
                !saveNewAlertLoading && 
                <React.Fragment>
                  <Save />
                  <Typography style={{ color: 'white' }}>Save</Typography>
                </React.Fragment>
              }
              { saveNewAlertLoading && <CircularProgress color='info' size={24} /> }
            </Button>
          </Grid>
        </Dialog>
        <Snackbar open={errorMessage ? true : false} autoHideDuration={2500}>
          <Alert severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
}
