import { API } from "aws-amplify";
import * as customQueries from '../../graphql/custom-queries';

export async function getSensorEventSeries(sensorID, startDate, endDate) {
  let nextToken = null;
  let queryItems = [];

  let queryVariables = {
    sensorID: sensorID,
    eventTimeStamp: {
      between: [startDate, endDate]
    },
    sortDirection: 'ASC',
  };

  do {
    queryVariables.nextToken = nextToken;

    const queryResult = await API.graphql({
      query: customQueries.sensorEventBySensor,
      variables: queryVariables
    });

    queryItems = queryItems.concat(queryResult.data['sensorEventBySensor'].items);
    nextToken = queryResult.data['sensorEventBySensor'].nextToken;
  } while (nextToken != null);

  return queryItems;
}
