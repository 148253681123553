import React, { useState, useEffect, useContext } from 'react';
import { Paper, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Dashboard, FiberManualRecord } from '@material-ui/icons';
import { VictoryScatter, VictoryLine } from 'victory';

import Navigator from '../../shared/components/navigator';
import Footer from '../../shared/components/footer';
import { globalDataStore } from '../../shared/contexts/globalDataContext';

import ResourcesMap from './components/resources-map.js';
import ResourcesTable from './components/resources-table.js';
import DownloadModal from './components/download-modal.js';
import { getAllLoggersForUser } from '../../shared/api-calls/logger-api-calls';
import { mapLoggerItemToResource } from '../../shared/utils/maps';
import { Auth } from 'aws-amplify';
import { getCloudAlertRules, getCloudAlertStateEvents, getLatestCloudAlertStateEvent, getNotificationRules } from '../../shared/api-calls/notification-api-calls';
import { asyncForEach } from '../../shared/utils/arrays';
import { findResourcePropertyValue, getAlertCategory } from '../../shared/utils/alert-utils';

export default function ResourcesOverview (props) {
  const [ loading, setLoading ] = useState(false);
  const [ resources, setResources] = useState([]);
  const [ cloudAlertRules, setCloudAlertRules ] = useState([]);
  const [ recentAlertHistory, setRecentAlertHistory ] = useState([]);

  const { state, dispatch } = useContext(globalDataStore);

  useEffect(() => {
    setLoading(true);

    Auth.currentAuthenticatedUser()
    .then(user => {
      getAllLoggersForUser(user.attributes.email)
      .then(loggerItems => {
        let resourceDetails = [];
        loggerItems.forEach(loggerItem => {
          let resourceDetailItems = mapLoggerItemToResource(loggerItem);
          if (Array.isArray(resourceDetailItems)) {
            resourceDetails = resourceDetails.concat(resourceDetailItems);
          }
          else {
            resourceDetails.push(resourceDetailItems);
          }
        })

        setResources(resourceDetails);
        dispatch({ type: 'resourceDetails', payload: resourceDetails });

        getCloudAlertRules()
        .then(cloudAlertRules => {
          dispatch({ type: 'alertRules', payload: cloudAlertRules });
          setCloudAlertRules(cloudAlertRules);

          let cloudAlertStateEvents = [];
          asyncForEach(cloudAlertRules, async cloudAlertRule => {
            const latestCloudAlertStateEvent = await getLatestCloudAlertStateEvent(cloudAlertRule.id)
            .catch(e => {
              console.log("error in getCloudAlertStateEvents", e);
            });

            if (latestCloudAlertStateEvent != null) {
              cloudAlertStateEvents.push(latestCloudAlertStateEvent);
            }
          })
          .then(() => {
            setRecentAlertHistory(cloudAlertStateEvents);
            setLoading(false);
          });
        });
      });
    });
  }, []);

  return (
    <div style={{flexGrow: 1, marginLeft: 240, backgroundColor: '#F7F7F7'}}>
      <Navigator activeDrawerItem='Home' />
      <Grid style={{margin: 24, width: '95%'}}>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50}} alignItems='center'>
          <Grid item style={{marginLeft: 10, marginRight: 10}}>
            <Dashboard fontSize='large' color='primary' />
          </Grid>
          <Grid item >
            <Typography variant='body1' style={{fontWeight: 'bold'}}>Overview</Typography>
          </Grid>
        </Grid>
        <Grid container style={{marginTop: 24}} >
          <Grid container item xs={3}>
            <Grid item xs={6}>
              <Paper style={{backgroundColor: '#4050B5', height: 120, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                <Typography align='center' variant='body2' gutterBottom style={{backgroundColor: '#39449A', marginLeft: '10%', marginRight: '10%', borderRadius: 8, padding: '5%', color: 'white'}}>Total Sites</Typography>
                <Typography align='center' variant='h4' style={{color: 'white'}}>{loading ? <Skeleton /> : resources.length}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{backgroundColor: 'white', height: 120, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                <Typography align='center' variant='body2' gutterBottom style={{backgroundColor: '#EDEDED', marginLeft: '10%', marginRight: '10%', borderRadius: 8, padding: '5%'}}>Monitored</Typography>
                <Typography align='center' variant='h4'>{loading ? <Skeleton /> : (resources.filter(resource => resource.monitored)).length}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{backgroundColor: 'white', height: 120, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                <Typography align='center' variant='body2' gutterBottom style={{backgroundColor: '#EDEDED', marginLeft: '10%', marginRight: '10%', borderRadius: 8, padding: '5%'}}>
                  <FiberManualRecord fontSize='small' style={{color: '#59AF43'}}/>
                  Online
                </Typography>
                <Typography align='center' variant='h4'>{loading ? <Skeleton /> : (resources.filter(resource => resource.online)).length}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{backgroundColor: 'white', height: 120, marginRight: 12, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                <Typography align='center' variant='body2' gutterBottom style={{backgroundColor: '#EDEDED', marginLeft: '10%', marginRight: '10%', borderRadius: 8, padding: '5%'}}>
                  <FiberManualRecord fontSize='small'/>
                  Offline
                </Typography>
                <Typography align='center' variant='h4'>{loading ? <Skeleton /> : (resources.filter(resource => resource.monitored)).length - (resources.filter(resource => resource.online)).length}</Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={9} style={{ height: 260 }}>
            <ResourcesMap resources={resources} />
          </Grid>
        </Grid>
        <Grid container direction='row' style={{backgroundColor: 'white', borderRadius: 8, height: 50, marginTop: 14}} alignItems='center'>
          <Grid style={{marginLeft: 10}} item >
            <Typography variant='body2' style={{fontWeight: 'bold'}}>Recent Alert History</Typography>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table size='small' stickyHeader aria-label='Recent Alert History Table'>
            <TableHead>
              <TableRow selected>
                <TableCell>Site</TableCell>
                <TableCell align='left'>Alert Category</TableCell>
                <TableCell align='left'>State</TableCell>
                <TableCell align='left'>Date</TableCell>
                <TableCell align='left'>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {recentAlertHistory.map((recentAlertHistoryObject) => (
              <TableRow key={recentAlertHistoryObject.id}>
                <TableCell component='th' scope='row'>
                  {(cloudAlertRules.find(cloudAlertRule => cloudAlertRule.id === recentAlertHistoryObject.cloudAlertRuleID)).siteName}
                </TableCell>
                <TableCell align='left'>{(cloudAlertRules.find(cloudAlertRule => cloudAlertRule.id === recentAlertHistoryObject.cloudAlertRuleID)).category}</TableCell>
                <TableCell align='left'>{recentAlertHistoryObject.state === 'true' ? 'Active' : 'Inactive'}</TableCell>
                <TableCell align='left'>{new Date(recentAlertHistoryObject.eventTimeStamp).toLocaleDateString()}</TableCell>
                <TableCell align='left'>{new Date(recentAlertHistoryObject.eventTimeStamp).toLocaleTimeString()}</TableCell>
              </TableRow>
            ))} 
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}
